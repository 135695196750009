import React, {
  memo,
  useEffect
} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { useInput } from 'react-hookedup';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  createInstantDemo,
  updateDemo
} from 'action/demo';
import FormControl from '@material-ui/core/FormControl';
import {
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {
  getProducts,
  getLanguages
} from 'action/demo';
import { KeyboardDatePicker } from '@material-ui/pickers';
import countries from '../../../../../common/src/util/countries.json';
import useTranslation from 'hook/useTranslation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    minWidth: 350
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  minText: {
    '&::before': {
      content: '"min"',
      display: 'block',
      position: 'absolute',
      right: 5,
      bottom: 6
    }
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  dialogHeader: {
    '& h2': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  customTimeWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    marginBottom: theme.spacing(1),
    '& > span': {
      marginLeft: `-${theme.spacing(1)}px`
    },
    '& > div': {
      flexGrow: 1
    },
  }
}));

const EventDialog = ({ open, selectedDateForCreateEvent, selectedEvent, onClose }) => {
  const classes = useStyles();
  const eventDuration = useInput(15);
  const eventDate = useInput(moment().format());
  const selectedProduct = useInput('');
  const selectedCountryCode = useInput('');
  const selectedLanguage = useInput('');
  const buyerName = useInput('');
  const buyerEmail = useInput('');
  const buyerPhone = useInput('');
  const customEventStartTime = useInput(moment().format('HH:mm'));
  const { products, languages } = useSelector(state => state.demo);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedDateForCreateEvent) {
      eventDate.setValue(moment(selectedDateForCreateEvent).format());
      customEventStartTime.setValue(moment(selectedDateForCreateEvent).format('HH:mm'));
    } else if (open) {
      customEventStartTime.setValue(moment().format('HH:mm'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateForCreateEvent, open]);

  useEffect(() => {
    if (!products.length) {
      dispatch(getProducts());
      dispatch(getLanguages());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedEvent) {
      eventDuration.setValue(Math.abs(moment(selectedEvent.extendedProps.endAt).diff(moment(selectedEvent.extendedProps.startAt), 'minutes')));
      selectedProduct.setValue(selectedEvent.extendedProps.productShortName);
      selectedCountryCode.setValue(selectedEvent.extendedProps.countryCode);
      selectedLanguage.setValue(selectedEvent.extendedProps.languageCode);
      eventDate.setValue(moment(selectedEvent.start.toString()).format());
    }
    return () => {
      eventDuration.setValue(15);
      selectedProduct.setValue('');
      selectedCountryCode.setValue('');
      selectedLanguage.setValue('');
      eventDate.setValue(moment().format());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedEvent && open) {
      customEventStartTime.setValue(moment(selectedEvent.start.toString()).format('HH:mm'));
    }
    // eslint-disable-next-line
  }, [selectedEvent, open])

  const isInvalidEventDuration = !eventDuration.value || parseInt(eventDuration.value) > 60 || parseInt(eventDuration.value) < 10;
  const isInvalidEventDate = (!eventDate.value || !moment(eventDate.value).isValid()) || moment(eventDate.value).isBefore(moment(), 'day');
  const isInvalidCustomTime = (customEventStartTime.value &&
    moment(eventDate.value).isSameOrBefore(moment(), 'day') &&
    moment().hour(customEventStartTime.value.split(':')[0]).minute(customEventStartTime.value.split(':')[1]).isBefore(moment()));
  const hasNoTime = !customEventStartTime.value;
  const isDisabledSubmit = isInvalidEventDate || isInvalidEventDuration || isInvalidCustomTime || hasNoTime || !selectedProduct.value || !selectedCountryCode.value || !selectedLanguage.value;

  const onSave = () => {
    let startAt, endAt;

    const [startAtHour, startAtMinute] = customEventStartTime.value.split(':');
    startAt = moment(eventDate.value).hour(startAtHour).minute(startAtMinute).format();
    endAt = moment(eventDate.value).hour(startAtHour).minute(startAtMinute).add(eventDuration.value, 'minutes').format();

    const params = { startAt, endAt };
    if (selectedProduct.value) params.productShortName = selectedProduct.value;
    if (selectedCountryCode.value) params.countryCode = selectedCountryCode.value;
    if (selectedLanguage.value) params.languageCode = selectedLanguage.value;

    if (buyerName.value || buyerEmail.value || buyerPhone.value) {
      const buyer = params.buyer = {};
      if (buyerName.value) buyer.displayName = buyerName.value;
      if (buyerEmail.value) buyer.email = buyerEmail.value;
      if (buyerPhone.value) buyer.phone = buyerPhone.value;
    }

    if (selectedEvent) params.id = selectedEvent.id;

    if (!selectedEvent) {
      dispatch(createInstantDemo(params));
    } else {
      dispatch(updateDemo(params))
    }

    resetFields();
    onClose();
  }

  const resetFields = () => {
    selectedCountryCode.setValue('');
    selectedProduct.setValue('');
    selectedLanguage.setValue('');
    buyerName.setValue('');
    buyerEmail.setValue('');
    buyerPhone.setValue('');
    eventDuration.setValue(15);
  }

  const renderInputFields = ({ label, value, onChange }) => (
    <TextField
      className={clsx(classes.input)}
      value={value}
      label={label}
      onChange={onChange}
    />
  )

  const renderBuyerFields = () => {
    return (
      <>
        {renderInputFields({ label: t('Buyer name'), value: buyerName.value, onChange: buyerName.onChange })}
        {renderInputFields({ label: t('Buyer email'), value: buyerEmail.value, onChange: buyerEmail.onChange })}
        {renderInputFields({ label: t('Buyer phone number'), value: buyerPhone.value, onChange: buyerPhone.onChange })}
      </>
    )
  }

  const renderProductSelect = () => {
    if (!products.length) return;
    return (
      <FormControl className={classes.input}>
        <InputLabel id="presentations-select">{t('Presentation')}</InputLabel>
        <Select
          labelId="presentations-select-label"
          id="presentations-select"
          value={selectedProduct.value}
          required={true}
          onChange={selectedProduct.onChange}
        >
          {
            products.map(item => (
              <MenuItem key={item.id} value={item.shortName}>{item.shortName} - {item.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    )
  }

  const renderCountryCodes = () => {
    return (
      <FormControl className={classes.input}>
        <InputLabel id="country-code-select">{t('Country Code')}</InputLabel>
        <Select
          labelId="country-code-select-label"
          id="country-code-select"
          required={true}
          value={selectedCountryCode.value}
          onChange={selectedCountryCode.onChange}
        >
          {
            Object.keys(countries).map(key => (
              <MenuItem key={key} value={key}>{`${key} - ${countries[key]}`}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  }

  const renderLanguageCodes = () => {
    return (
      <FormControl className={classes.input}>
        <InputLabel id="language-code-select">{t('Language Code')}</InputLabel>
        <Select
          labelId="language-code-select-label"
          id="language-code-select"
          required={true}
          value={selectedLanguage.value}
          onChange={selectedLanguage.onChange}
        >
          {
            languages.map(lang => (
              <MenuItem key={lang} value={lang}>{lang}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        className={classes.dialogHeader}
      >
        {selectedEvent ? t('Edit selected event') : t('Create event')}
      </DialogTitle>
      <DialogContent className={classes.root}>
        <TextField
          className={clsx(classes.input, {
            [classes.minText]: !isInvalidEventDuration
          })}
          required={true}
          value={eventDuration.value}
          label={t('Event duration')}
          helperText={isInvalidEventDuration ? t('Event duration should be between 10 - 60 min') : ''}
          error={isInvalidEventDuration}
          onChange={eventDuration.onChange}
        />
        <KeyboardDatePicker
          className={classes.input}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          required={true}
          error={isInvalidEventDate}
          id="date-picker-inline"
          label={t('Event date')}
          helperText={isInvalidEventDate ? t('Date should be current or in the future') : ''}
          value={eventDate.value}
          onChange={(val) => eventDate.setValue(val)}
          KeyboardButtonProps={{
            'aria-label': t('change date'),
          }}
        />
        <Box className={classes.customTimeWrapper}>
          <TextField
            id="time"
            value={customEventStartTime.value}
            label={t('Start time')}
            type="time"
            error={isInvalidCustomTime}
            helperText={isInvalidCustomTime ? t('Time should be after the current time!') : ''}
            disabled={!eventDuration.value}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={customEventStartTime.onChange}
          />
        </Box>
        {
          renderProductSelect()
        }
        {
          renderCountryCodes()
        }
        {
          renderLanguageCodes()
        }
        {
          renderBuyerFields()
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          color="primary"
          disabled={isDisabledSubmit}
          autoFocus
          onClick={onSave}
        >
          {selectedEvent ? t('Save') : t('Create')}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default memo(EventDialog);
