import { SET_TOAST } from '../action/toast';

const initialState = {
  open: false,
  message: '',
  alertType: undefined,
  permanent: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOAST:
      return {
        ...state,
        ...action.data
      }
    default:
      return state;
  }
};

export default reducer;