import axios from 'axios';
import config from 'config';
import {
  setToast,
  SUCCESS_TYPE,
  ERROR_TYPE
} from './toast';

const { vpdApiUrl } = config;

export const UPDATE_DEMOS = 'UPDATE_DEMOS';
export const UPDATE_SPECIFIC_DEMO = 'UPDATE_SPECIFIC_DEMO';
export const UPDATE_DELETED_DEMOS = 'UPDATE_DELETED_DEMOS';
export const UPDATE_PARTICIPANT_DIALOG = 'UPDATE_PARTICIPANT_DIALOG';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_LANGUAGES = 'SET_LANGUAGES';

const triggerToast = (dispatch, message, alertType) => {
  dispatch(setToast({
    open: true,
    message,
    alertType
  }));
}

const updateDemos = demos => ({
  type: UPDATE_DEMOS,
  demos
});

const updateDeletedDemo = demoId => ({
  type: UPDATE_DELETED_DEMOS,
  demoId
});

const updateSpecificDemo = demo => ({
  type: UPDATE_SPECIFIC_DEMO,
  demo
});

export const setProducts = products => ({
  type: SET_PRODUCTS,
  products
});

export const setLanguages = data => ({
  type: SET_LANGUAGES,
  data
})

export const updateParticipantDialog = data => ({
  type: UPDATE_PARTICIPANT_DIALOG,
  data
});

export const getDemos = () => async dispatch => {
  try {
    const result = await axios.get(`${vpdApiUrl}/demo`, { withCredentials: true });
    dispatch(updateDemos(result.data.demos));
  } catch (e) {
    console.log('ERR', e);
  }
};

export const createInstantDemo = data => async dispatch => {
  try {
    const result = await axios.post(`${vpdApiUrl}/demo`, data, { withCredentials: true });
    dispatch(updateDemos(result));
    triggerToast(dispatch, 'Created new demo', SUCCESS_TYPE);

    if (window.self !== window.top) // i.e. in iframe/frame
      window.top.postMessage(JSON.stringify({
        type: 'demoCreated',
        demo: result.data,
      }), '*');

  } catch (e) {
    console.log('ERR', e);
    triggerToast(dispatch, 'Create demo failed', ERROR_TYPE);
  }
};

export const deleteDemo = demoId => async dispatch => {
  try {
    const result = await axios.delete(`${vpdApiUrl}/demo/${demoId}`, { withCredentials: true });
    if (result.data.ok) {
      dispatch(updateDeletedDemo(demoId));
    }
  } catch (e) {
    console.log('ERR', e);
    triggerToast(dispatch, 'Demo delete failed', ERROR_TYPE);
  }
}

export const updateDemo = data => async dispatch => {
  try {
    const demo = await axios.post(`${vpdApiUrl}/demo/${data.id}`, data, { withCredentials: true });
    dispatch(updateSpecificDemo(demo));
  } catch (e) {
    console.log('ERR', e);
  }
}

export const updateParticipant = data => async dispatch => {
  try {
    await axios.post(`${vpdApiUrl}/demo/participant/${data.id}`, data, { withCredentials: true });
    dispatch(updateParticipantDialog(false));
  } catch (e) {
    console.log('ERR', e);
    triggerToast(dispatch, 'Insert buyer name has failed', ERROR_TYPE)
  }
}

export const getProducts = () => async dispatch => {
  try {
    const result = await axios.get(`${vpdApiUrl}/product`, { withCredentials: true });
    dispatch(setProducts(result.data.products));
  } catch (e) {
    console.log('ERR', e);
  }
}

export const getLanguages = () => async dispatch => {
  try {
    const result = await axios.get(`${vpdApiUrl}/language`, { withCredentials: true });
    dispatch(setLanguages(result.data.languages));
  } catch (e) {
    console.log('ERR', e);
  }
}
