import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { signIn } from '../../../action/user';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useLocation } from 'react-router';

const authorize = Component => (props) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { checkedSignIn, user } = useSelector(state => state.user);
  const params = new URLSearchParams(search);
  const token = params.get('token') || '';
  const isSignedIn = !!user;

  useEffect(() => {
    dispatch(signIn(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!checkedSignIn) return null;
  if (!isSignedIn) return <Redirect to={'/unauthorized'}/>;

  return <Component {...props} />;
}

export default authorize;
