import {
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  fetchTranslations,
  addMissingTranslation
} from '../action/translation';
import Polyglot from 'node-polyglot';

export default function useTranslation() {

  const { polyglotForLanguage, language, locale } = useSelector(state => state.translation);
  const dispatch = useDispatch();
  const polyglot = polyglotForLanguage[language];

  const setLanguage = language => dispatch(fetchTranslations(language));

  useEffect(() => {
    if (!polyglot)
      dispatch(fetchTranslations(language));
  }, [dispatch, language, polyglot]);

  let t;
  if (polyglot) {
    t = (key, options = undefined) => {
      if (!key)
        return key;

      const translate = polyglot.t.bind(polyglot);
      const has = polyglot.has.bind(polyglot);

      if (has(key)) {
        return translate(key, options) || Polyglot.transformPhrase(key, options, locale);
      } else {
        addMissingTranslation(key);
        return Polyglot.transformPhrase(key, options, locale);
      }
    }
  } else {
    t = (key, options = undefined) => Polyglot.transformPhrase(key, options, locale);
  }

  // t used as polyglot's t function
  // ref. https://www.npmjs.com/package/node-polyglot
  return { t, setLanguage };
}
