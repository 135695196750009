import { uniqBy } from 'lodash';

import {
  UPDATE_DEMOS,
  UPDATE_DELETED_DEMOS,
  UPDATE_SPECIFIC_DEMO,
  UPDATE_PARTICIPANT_DIALOG,
  SET_PRODUCTS,
  SET_LANGUAGES
} from '../action/demo';

const initialState = {
  demos: [],
  products: [],
  languages: [],
  openParticipantDialog: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DEMOS:
      const demoData = Array.isArray(action.demos) ?
        action.demos.map(item => ({ ...item, start: item.startAt, end: item.endAt })) :
        [{ ...action.demos.data, start: action.demos.data.startAt, end: action.demos.data.endAt }];
      return {
        ...state,
        demos: uniqBy([...demoData, ...state.demos], 'id')
      }
    case UPDATE_DELETED_DEMOS:
      return {
        ...state,
        demos: state.demos.filter(item => item.id !== action.demoId)
      }
    case UPDATE_SPECIFIC_DEMO:
      return {
        ...state,
        demos: state.demos.map(item => {
          if (item.id === action.demo.data.id) {
            return { ...action.demo.data, start: action.demo.data.startAt, end: action.demo.data.endAt }
          }
          return item;
        })
      }
    case UPDATE_PARTICIPANT_DIALOG:
      return {
        ...state,
        openParticipantDialog: action.data
      }
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products
      }
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.data
      }
    default:
      return state;
  }
};

export default reducer;
