import {
  SIGNED_IN,
  SIGNED_OUT,
  UPDATED_USER,
} from '../action/user';
import { deepCopy } from '../../../../common/src/util/object';

const initialState = {
  checkedSignIn: false,
  user: undefined, // signed-in user
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNED_OUT:
      return {
        ...state,
        user: undefined,
        checkedSignIn: true,
      };
    case UPDATED_USER:
    case SIGNED_IN: {
      const user = deepCopy(action.user);
      return {
        ...state,
        user,
        checkedSignIn: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
