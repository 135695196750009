const deepCopy = obj => {
  return JSON.parse(JSON.stringify(obj));
};

const prettyJSON = (obj, ident = 2) => JSON.stringify(obj, null, ident);

const deepFreeze = obj => {
  Object.freeze(obj);

  Object.getOwnPropertyNames(obj).forEach(prop => {
    if (Object.prototype.hasOwnProperty.call(obj, prop)
      && obj[prop] !== null
      && (typeof obj[prop] === 'object' || typeof obj[prop] === 'function')
      && !Object.isFrozen(obj[prop])) {
      deepFreeze(obj[prop]);
    }
  });

  return obj;
};

/**
 * Simplified version of lodash.getPath to fetch a nested value.
 * Examples:
 * - deepValue(obj, 'a.b.1')
 * - deepValue(obj, ['a','b','1'])
 * - deepValue(obj, 'a.b.1', 'my default value if property does not exist')
 *
 * @param obj Object in which to search for the nested value.
 * @param path An array of strings or single string with '.' as properties delimiter.
 * @param defaultValue Optional parameter (default: undefined) to return in case the nested value is not found (undefined).
 * @returns The nested value defined by the path or defaultValue if not found in the obj
 */
const deepValue = (obj, path, defaultValue) => {
  if (obj === undefined || obj === null) {
    return defaultValue;
  }

  let properties;
  if (Array.isArray(path)) {
    properties = path;
  } else if (typeof path === 'string') {
    properties = path.split('.');
  } else {
    throw new Error(`Argument error for path ${path}`);
  }

  if (properties.length === 0) {
    return defaultValue;
  }

  let current = obj;
  for (let p = 0; p < properties.length; p++) {
    let property = properties[p];
    current = current[property];
    // If not evaluating the deepest property and value is undefined/null, then stop and return defaultValue
    if (p !== properties.length - 1 && (current === undefined || current === null)) {
      return defaultValue;
    }
  }
  return current;
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
const isObject = item => Boolean(
  item &&
  typeof item === 'object' &&
  !Array.isArray(item) &&
  !(item instanceof Date),
);

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
const deepMerge = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepMerge(target, ...sources);
};

// Ref. https://stackoverflow.com/questions/25456013/javascript-deepequal-comparison/25456134
//check if value is primitive
const _isPrimitive = obj => obj !== Object(obj);
const deepEqual = (obj1, obj2) => {

  if (obj1 === obj2) // it's just the same object. No need to compare.
    return true;

  if (_isPrimitive(obj1) && _isPrimitive(obj2)) // compare primitives
    return obj1 === obj2;

  if (Object.keys(obj1).length !== Object.keys(obj2).length)
    return false;

  // compare objects with same number of keys
  for (let key in obj1) {
    if (!(key in obj2)) return false; //other object doesn't have this prop
    // noinspection JSUnfilteredForInLoop
    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};


module.exports.deepCopy = deepCopy;
module.exports.prettyJSON = prettyJSON;
module.exports.deepFreeze = deepFreeze;
module.exports.deepValue = deepValue;
module.exports.isObject = isObject;
module.exports.deepMerge = deepMerge;
module.exports.deepEqual = deepEqual;
