import React, {
  memo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { getDay } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { DatePickerCalendar } from 'react-nice-dates'
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import BuyerSelectTime from 'component/buyerSelectTime';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import NextIcon from '@material-ui/icons/NavigateNext';
import BuyerCalendarForm from 'component/buyerCalendarForm';
import 'react-nice-dates/build/style.css'
import useTranslation from 'hook/useTranslation';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    position: 'relative',
    overflow: 'hidden'
  },
  dateTimeBox: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    transition: 'all 500ms ease'
  },
  formBox: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: `calc(100% - ${theme.spacing(4)}px)`,
    opacity: 0,
    pointerEvents: 'none',
    transform: 'translateX(100px)',
    transition: 'all 500ms ease',
    // transitionDelay: 250
  },
  boxDate: {
    flexGrow: 3,
    marginRight: theme.spacing(2),
    transition: 'flex-grow 250ms ease'
  },
  boxTime: {
    position: 'relative',
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
    transition: 'opacity 250ms ease'
  },
  datePicker: {
    '& .-selected::before': {
      backgroundColor: theme.palette.primary.main
    },
    '& .nice-dates-day::after': {
      border: `2px solid ${theme.palette.primary.main}`
    },
    '& .nice-dates-day:not(.-disabled)::before': {
      backgroundColor: theme.palette.light.main,
      opacity: 1
    }
  },
  centerText: {
    textAlign: 'center',
    color: 'rgba(0,0,0,0.6)'
  },
  controls: {
    position: 'absolute',
    marginTop: 'auto',
    display: 'flex',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    justifyContent: 'flex-end',
    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  activeControlBtn: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    '& svg': {
      color: 'white'
    }
  },
  wide: {
    flexGrow: 5
  },
  hiddenTimeBlock: {
    opacity: 0,
    pointerEvents: 'none'
  },
  moveLeft: {
    transform: 'translateX(-100px)',
    opacity: 0,
    pointerEvents: 'none'
  },
  formBoxActive: {
    opacity: 1,
    transform: 'translateX(0)',
    pointerEvents: 'all'
  }
}));

const modifiers = (availableDays) => ({
  disabled: date => !availableDays.find(day => moment(date).isSame(day, 'day')) || getDay(date) === 6 || getDay(date) === 0
})

const Controls = memo(({ selectedTime, date, slide, setSlide }) => {
  const classes = useStyles();
  const isNextDisabled = slide === 1 || !selectedTime || !date;
  const isPrevDisabled = slide === 0;

  return (
    <Box className={classes.controls}>
      <IconButton
        className={clsx({
          [classes.activeControlBtn]: !isPrevDisabled
        })}
        disabled={isPrevDisabled}
        onClick={() => setSlide(0)}
      >
        <PrevIcon fontSize={'small'}/>
      </IconButton>
      <IconButton
        className={clsx({
          [classes.activeControlBtn]: !isNextDisabled
        })}
        disabled={isNextDisabled}
        onClick={() => setSlide(1)}
      >
        <NextIcon fontSize={'small'}/>
      </IconButton>
    </Box>
  )
});

const BuyerDateTimeSelection = ({ className, calendarData, date, setDate, selectedTime, setSelectedTime }) => {
  const classes = useStyles();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [slide, setSlide] = useState(0);
  const { t } = useTranslation();

  return (
    <Paper className={clsx(className, classes.paper)}>
      <Box className={clsx(classes.dateTimeBox, {
        [classes.moveLeft]: slide === 1
      })}>
        <Box
          className={clsx(classes.boxDate, classes.datePicker, {
            [classes.wide]: !date
          })}
        >
          <Typography className={classes.centerText} variant={'subtitle2'}>{t('Select event date')}</Typography>
          <DatePickerCalendar
            date={date}
            modifiers={modifiers(calendarData.availableDays)}
            locale={enGB}
            onDateChange={setDate}
          />
        </Box>
        <Box className={clsx(classes.boxTime, {
          [classes.hiddenTimeBlock]: !date
        })}>
          <Typography className={classes.centerText} variant={'subtitle2'}>{t('Select event time')}</Typography>
          <BuyerSelectTime
            date={date}
            slide={slide}
            setSlide={setSlide}
            calendarData={calendarData}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
          />
        </Box>
      </Box>
      <Box className={clsx(classes.formBox, {
        [classes.formBoxActive]: slide === 1
      })}>
        <BuyerCalendarForm
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
        >
          <Typography className={clsx(classes.centerText)} variant={'subtitle2'}>{t('Enter details')}</Typography>
        </BuyerCalendarForm>
      </Box>
      {
        !isSubmitted && (
          <Controls
            date={date}
            slide={slide}
            setSlide={setSlide}
            selectedTime={selectedTime}
          />
        )
      }
    </Paper>
  )
}

BuyerDateTimeSelection.propTypes = {
  className: PropTypes.string,
  date: PropTypes.object,
  selectedTime: PropTypes.string,
  setSelectedTime: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  calendarData: PropTypes.shape({
    availableDays: PropTypes.array,
    availableTime: PropTypes.array
  }).isRequired
}

export default memo(BuyerDateTimeSelection);
