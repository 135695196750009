import React, {
  memo,
  useState
} from 'react';
import clsx from 'clsx';
import {
  Box,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import { useInput } from 'react-hookedup';
import Typography from '@material-ui/core/Typography';
import MuiPhoneInput from 'material-ui-phone-number';
import useTranslation from 'hook/useTranslation';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: 250,
    position: 'relative',
    margin: 'auto',
    transition: 'all 250ms ease'
  },
  input: {
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: 'initial',
    '& svg': {
      marginRight: 10
    }
  },
  firstInput: {
    marginTop: theme.spacing(3)
  },
  hide: {
    opacity: 0,
    pointerEvents: 'none'
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    transition: 'all 250ms ease',
    transitionDelay: 500,
    opacity: 0,

    position: 'absolute',
    left: 0,
    right: 0,
    transform: 'translateY(-130px)',
    pointerEvents: 'none'

  },
  roundIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 55,
    height: 55,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    '& svg': {
      color: 'white'
    }
  },
  isSubmitted: {
    opacity: 1,
    transform: 'translateY(-180px)'
  }
}));

const BuyerCalendarForm = ({ children, isSubmitted, setIsSubmitted }) => {
  const [showForm, setShowForm] = useState(true);
  const classes = useStyles();
  const name = useInput('');
  const email = useInput('');
  const phoneNumber = useInput('');
  const { t } = useTranslation();


  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isEmailValid = validateEmail(email.value);
  const isDisabled = !name.value || !email.value || !phoneNumber.value || !isEmailValid;

  const onSubmit = () => {
    setShowForm(false);
    setIsSubmitted(true);
  }

  const renderSubmittedMessage = () => {
    return (
      <Box className={clsx(classes.centered, {
        [classes.isSubmitted]: isSubmitted
      })}>
        <Box className={classes.roundIcon}>
          <DoneIcon/>
        </Box>
        <Box>
          <Typography variant={'h5'}>{t('Event successfully scheduled!')}</Typography>
          <Typography variant={'body2'}>{t('You will be notified 15 minutes before the event.')}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        className={clsx(classes.root, {
          [classes.hide]: !showForm
        })}
      >
        {children}
        <TextField
          className={clsx(classes.input, classes.firstInput)}
          label={t('Full name')}
          value={name.value}
          variant={'outlined'}
          size={'small'}
          required={true}
          onChange={name.onChange}
        />
        <TextField
          className={classes.input}
          label={t('Email')}
          value={email.value}
          variant={'outlined'}
          size={'small'}
          required={true}
          error={!isEmailValid}
          onChange={email.onChange}
        />
        <MuiPhoneInput
          defaultCountry='se'
          placeholder={t('Phone number')}
          variant={'outlined'}
          size={'small'}
          required={true}
          {...phoneNumber.bind}
        />
        <Button
          className={classes.button}
          variant={'contained'}
          color={'primary'}
          disabled={isDisabled}
          onClick={onSubmit}
        >
          <DoneIcon fontSize={'small'}/>
          {t('Schedule Event')}
        </Button>
      </Box>
      {renderSubmittedMessage()}
    </Box>
  )
}

export default memo(BuyerCalendarForm);
