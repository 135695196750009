export const REFRESH_DEVICES = 'REFRESH_DEVICES';
export const UPDATE_INPUT_DEVICE_ID = 'UPDATE_INPUT_DEVICE_ID';
export const UPDATE_OUTPUT_DEVICE_ID = 'UPDATE_OUTPUT_DEVICE_ID';
export const UPDATE_MUTED = 'UPDATE_MUTED';

export const refreshDevices = () => async dispatch => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  dispatch({ type: REFRESH_DEVICES, devices });
}

export const updateInputDeviceId = deviceId => dispatch =>
  dispatch({ type: UPDATE_INPUT_DEVICE_ID, deviceId });

export const updateOutputDeviceId = deviceId => dispatch =>
  dispatch({ type: UPDATE_OUTPUT_DEVICE_ID, deviceId });

export const updateMuted = muted => dispatch =>
  dispatch({ type: UPDATE_MUTED, muted });
