import axios from 'axios';
import config from 'config';

const { vpdApiUrl } = config;

export const FETCHED_TRANSLATIONS = 'FETCHED_TRANSLATIONS';
export const FETCHED_LANGUAGES = 'FETCHED_LANGUAGES';
export const CHANGED_TRANSLATION_LANGUAGE = 'CHANGED_TRANSLATION_LANGUAGE';

export const fetchedTranslations = (language, locale, translations) => ({
  type: FETCHED_TRANSLATIONS,
  language,
  translations,
  locale,
});

const fetchingTranslations = {};

export const changedTranslationLanguage = language => ({
  type: CHANGED_TRANSLATION_LANGUAGE,
  language,
});

export const fetchTranslations = lang => async dispatch => {
  try {
    dispatch(changedTranslationLanguage(lang));

    if (fetchingTranslations[lang])
      return;
    fetchingTranslations[lang] = true;

    const res = await axios.get(`${vpdApiUrl}/translation/${lang}`, { withCredentials: true });
    const { language, locale, translations } = res.data;
    dispatch(fetchedTranslations(language, locale, translations));
  } catch (e) {
    console.error(e);
  }
};

const addedMissingTranslations = {};
export const addMissingTranslation = key => {
  if (addedMissingTranslations[key])
    return;
  (async () => {
    addedMissingTranslations[key] = true;
    await axios.post(`${vpdApiUrl}/translation`, { key }, { withCredentials: true });
  })();
}

export const fetchedLanguages = languages => ({
  type: FETCHED_LANGUAGES,
  languages,
});

export const fetchLanguages = () => async dispatch => {
  try {
    const res = await axios.get(`${vpdApiUrl}/translation/language`, { withCredentials: true });
    const { languages } = res.data;
    dispatch(fetchedLanguages(languages));
  } catch (e) {
    console.error(e);
  }
};
