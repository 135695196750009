import React, { memo } from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TodayIcon from '@material-ui/icons/Today';
import WatchIcon from '@material-ui/icons/QueryBuilder';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import GroupIcon from '@material-ui/icons/PeopleOutline';
import LinkIcon from '@material-ui/icons/Link';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import HourglassIcon from '@material-ui/icons/HourglassEmptyOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import ProductIcon from '@material-ui/icons/FolderOpen';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import useTranslation from 'hook/useTranslation';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent'
  },
  iconsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& > button': {
      marginLeft: theme.spacing(1)
    }
  },
  title: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
  },
  flex: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > svg': {
      marginRight: theme.spacing(2)
    }
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  joinButton: {
    textTransform: 'capitalize',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  mainWrapper: {
    maxWidth: 400
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontSize: 12,
  },
  joinButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .copy-seller-url-button': {
      display: 'none'
    },
    '&:hover .copy-seller-url-button': {
      display: 'block'
    }
  },
  buyerUrlWrapper: {
    position: 'relative',
    '& .buyer-copy-to-clipboard': {
      position: 'absolute',
      right: 0,
      top: -15,
      display: 'none'
    },
    '&:hover .buyer-copy-to-clipboard': {
      display: 'block'
    },
  }
}));

const EventDetailsPopover = ({ open, selectedEvent, onClose, onEdit, onRemove, isDev }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { locale } = useSelector(state => state.translation);
  moment.locale(locale);

  if (selectedEvent?.display === 'background')
    return null;
  const seller = selectedEvent?.extendedProps.participants.find(item => item.isSeller);
  const buyer = selectedEvent?.extendedProps.participants.find(item => !item.isSeller);

  const getReminderTime = () => {
    const minutes = parseInt(moment(selectedEvent.extendedProps.reminder).format('m'))
    return t(`%{minutes} minutes before event`, { minutes });
  }

  const renderClipboardButton = (clipBoardText, tooltipText) => (
    <CopyToClipboard
      className={'copy-seller-url-button'}
      text={clipBoardText}
    >
      <Tooltip title={tooltipText}>
        <IconButton>
          <FileCopyIcon fontSize={'small'}/>
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );

  if (!selectedEvent) return null;

  return (
    <Dialog
      open={open}
      BackdropProps={{
        classes: {
          root: classes.root
        }
      }}
      onClose={onClose}
    >
      <Box p={2} className={classes.mainWrapper}>
        <Box className={classes.iconsWrapper}>
          {
            isDev && moment(selectedEvent.start.toString()).isAfter(moment()) && (
              <Tooltip title={t('Edit demo')}>
                <IconButton
                  size={'small'}
                  onClick={onEdit}
                >
                  <EditIcon fontSize="inherit"/>
                </IconButton>
              </Tooltip>
            )
          }
          {
            isDev && <Tooltip title={t('Remove demo')}>
              <IconButton
                size={'small'}
                onClick={onRemove}
              >
                <DeleteIcon fontSize="inherit"/>
              </IconButton>
            </Tooltip>
          }
          <Tooltip title={t('Close')}>
            <IconButton size={'small'} onClick={onClose}>
              <CloseIcon fontSize="inherit"/>
            </IconButton>
          </Tooltip>
        </Box>

        <Typography className={classes.title} variant={'h5'}>{selectedEvent.title}</Typography>
        <Box className={classes.flex}>
          <TodayIcon fontSize="small" color={'action'}/>
          <Typography variant={'body2'}>
            {`${moment(selectedEvent.start).format('LLL')} - ${moment(selectedEvent.end).format('LT')}`}
          </Typography>
        </Box>
        {
          selectedEvent.extendedProps.productShortName && (
            <Box className={classes.flex}>
              <ProductIcon fontSize="small" color={'action'}/>
              <Typography variant={'body2'} className={classes.capitalize}>{t('Product')}: {selectedEvent.extendedProps.productShortName}</Typography>
            </Box>
          )
        }
        <Box className={classes.flex}>
          <WatchIcon fontSize="small" color={'action'}/>
          <Typography variant={'body2'}>
            {`${moment(selectedEvent.end).diff(moment(selectedEvent.start), 'minutes')} ${t('minutes')}`}
          </Typography>
        </Box>
        {
          selectedEvent.extendedProps.guests && (
            <Box className={classes.flex}>
              <GroupIcon fontSize="small" color={'action'}/>
              <Typography variant={'body2'}>{t('Guests')}: {selectedEvent.extendedProps.guests}</Typography>
            </Box>
          )
        }
        {
          selectedEvent.extendedProps.status && (
            <Box className={classes.flex}>
              <HourglassIcon fontSize="small" color={'action'}/>
              <Typography variant={'body2'} className={classes.capitalize}>{t('Status')}: <b>{selectedEvent.extendedProps.status}</b></Typography>
            </Box>
          )
        }
        {
          selectedEvent.extendedProps.countryCode && (
            <Box className={classes.flex}>
              <LanguageIcon fontSize="small" color={'action'}/>
              <Typography variant={'body2'} className={classes.capitalize}>{t('Country Code')}: {selectedEvent.extendedProps.countryCode}</Typography>
            </Box>
          )
        }

        {
          buyer?.url && (
            <Box
              className={clsx(classes.flex, classes.buyerUrlWrapper)}
            >
              <LinkIcon fontSize="small" color={'action'}/>
              <Box>
                <Typography variant={'body2'}>{t('Buyer demo link')}:</Typography>
                <Typography variant={'body2'} className={classes.secondaryText}>{buyer.url}</Typography>
              </Box>
              <Box className={'buyer-copy-to-clipboard'}>
                {renderClipboardButton(buyer.url, t('Copy buyer demo link to clipboard'))}
              </Box>
            </Box>
          )
        }

        {
          selectedEvent.extendedProps.reminder && (
            <Box className={classes.flex}>
              <NotificationsIcon fontSize="small" color={'action'}/>
              <Typography variant={'body2'}>{getReminderTime()}</Typography>
            </Box>
          )
        }
        <Box className={classes.joinButtonWrapper}>
          <Button
            className={classes.joinButton}
            variant={'contained'}
            color={'primary'}
            onClick={() => window.open(seller.url)}
          >
            {t('Join demo presentation')}
          </Button>

          {renderClipboardButton(seller?.url, t('Copy demo link to clipboard'))}
        </Box>
      </Box>
    </Dialog>
  )
}

export default memo(EventDetailsPopover);
