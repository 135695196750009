import React, {
  memo,
  useEffect,
  useState
} from 'react';
import {
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import useTranslation from 'hook/useTranslation';

const useStyles = makeStyles(theme => ({
  warning: {
    maxWidth: 500,
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 999,
    paddingRight: theme.spacing(4),
  },
  closeButton: {
    backgroundColor: 'inherit',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  }
}));

const isIE = () => {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return (msie > 0 || trident > 0);
}

const BrowserWarningDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => setOpen(isIE()), []);

  return (
    open &&
    <Alert severity="error" className={classes.warning}>
      <Typography>{t('Your browser does not support voice communication. Please use another browser to enable voice, e.g. Chrome, Edge, Firefox, etc.')}</Typography>
      <IconButton onClick={() => setOpen(false)} className={classes.closeButton} size="small">
        <CloseIcon/>
      </IconButton>
    </Alert>
  );
}

export default memo(BrowserWarningDialog);
