import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: theme.spacing(10),
    // marginBottom: theme.spacing(2)
  },
}));

const PageRoot = ({ children, className, noContainer }) => {
  const classes = useStyles();

  if (noContainer) {
    return children;
  }

  return (
    <Container fixed className={clsx(classes.root, className)}>
      {children}
    </Container>
  )
};

export default React.memo(PageRoot);
