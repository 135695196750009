const { deepFreeze } = require('./object');

const EVENT_TYPE = deepFreeze({
  PING: 'PING', // client to server
  PONG: 'PONG', // server to client

  RECEIVED_ERROR: 'RECEIVED_ERROR', // server to client

  SEND_TEXT_MESSAGE: 'SEND_TEXT_MESSAGE', // client to server
  RECEIVED_TEXT_MESSAGE: 'RECEIVED_TEXT_MESSAGE', // server to client

  SET_DEMO_STATE: 'SET_DEMO_STATE', // client to server
  GET_DEMO_STATE: 'GET_DEMO_STATE', // client to server
  RECEIVED_DEMO_STATE: 'RECEIVED_DEMO_STATE', // server to client

  GET_DEMO: 'GET_DEMO', // client to server
  RECEIVED_DEMO: 'RECEIVED_DEMO', // server to client

  GET_PARTICIPANT: 'GET_PARTICIPANT', // client to server
  RECEIVED_PARTICIPANT: 'RECEIVED_PARTICIPANT', // server to client

  GET_PARTICIPANTS_STATE: 'GET_PARTICIPANTS_STATE', // client to server
  RECEIVED_PARTICIPANTS_STATE: 'RECEIVED_PARTICIPANTS_STATE', // server to client
  SET_PARTICIPANT_IS_MUTED: 'SET_PARTICIPANT_IS_MUTED',

  GET_PRESENTATION: 'GET_PRESENTATION', // client to server
  RECEIVED_PRESENTATION: 'RECEIVED_PRESENTATION', // server to client

  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS', // client to server
  RECEIVED_ALL_PRODUCTS: 'RECEIVED_ALL_PRODUCTS', // server to client

  SET_VIDEO_STATUS: 'SET_VIDEO_STATUS',
  GET_VIDEO_STATUS: 'GET_VIDEO_STATUS',

  UPDATE_BUYER: 'UPDATE_BUYER',
  GET_UPDATE_BUYER: 'GET_UPDATE_BUYER',

  END_DEMO: 'END_DEMO',
  GET_END_DEMO: 'GET_END_DEMO'
});

const ERROR_MESSAGES = {
  DEMO_ENDED: 'Demo has ended',
  DEMO_NOT_STARTED: 'Demo has not been started',
  DEMO_NOT_FOUND: 'Demo not found',
  PARTICIPANT_NOT_FOUND: 'Participant not found',
}

module.exports = {
  EVENT_TYPE,
  ERROR_MESSAGES
};
