import axios from 'axios';
import config from 'config';

const { vpdApiUrl } = config;

export const SIGNED_IN = 'SIGNED_IN';
export const SIGNED_OUT = 'SIGNED_OUT';
export const UPDATED_USER = 'UPDATED_USER';

export const signedIn = user => ({
  type: SIGNED_IN,
  user,
});

export const signIn = (token = '') => async dispatch => {
  try {
    const res = await axios({
      url: `${vpdApiUrl}/user/sign-in`,
      method: 'GET',
      headers: { 'X-Token': token },
      withCredentials: true,
    });
    dispatch(signedIn(res.data.user));
  } catch (_) {
    return dispatch(signedOut());
  }
};


export const signedOut = () => ({
  type: SIGNED_OUT,
});

export const signOut = () => async dispatch => {
  await axios({
    url: `${vpdApiUrl}/user/sign-out`,
    method: 'POST',
    withCredentials: true,
  });
  return dispatch(signedOut());
};

export const updatedUser = user => ({
  type: UPDATED_USER,
  user,
});

export const updateUser = mutations => async dispatch => {
  const res = await axios({
    url: `${vpdApiUrl}/user`,
    method: 'POST',
    data: mutations,
    withCredentials: true,
  });
  return dispatch(updatedUser(res.data.user));
}
