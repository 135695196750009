import { deepFreeze } from '../../../common/src/util/object';

// noinspection JSFileReferences
import { environment, proxyHost } from './env';

const config = deepFreeze({
  local: {
    environment,
    rootUrl: proxyHost ? `https://${proxyHost}` : 'http://localhost:3001',
    vpdApiUrl: proxyHost ? `https://${proxyHost}/api` : 'http://localhost:4001',
    vpdWsUrl: proxyHost ? `wss://${proxyHost}/api` : 'ws://localhost:4001',
    sentry: {
      enabled: false,
      dsn: 'https://760a570e9dfb486b8b173a6a994e37e6@o695437.ingest.sentry.io/5775616',
    },
  },
  test: {
    environment,
    rootUrl: 'https://vpd.reeinvent.com',
    vpdApiUrl: 'https://vpd.reeinvent.com/api',
    vpdWsUrl: 'wss://vpd.reeinvent.com/api',
    sentry: {
      enabled: true,
      dsn: 'https://760a570e9dfb486b8b173a6a994e37e6@o695437.ingest.sentry.io/5775616',
    },
  },
  staging: {
    environment,
    rootUrl: 'https://staging.vpd.veidec.com',
    vpdApiUrl: 'https://staging.vpd.veidec.com/api',
    vpdWsUrl: 'wss://staging.vpd.veidec.com/api',
    sentry: {
      enabled: true,
      dsn: 'https://760a570e9dfb486b8b173a6a994e37e6@o695437.ingest.sentry.io/5775616',
    },
  },
  production: {
    environment,
    rootUrl: 'https://vpd.veidec.com',
    vpdApiUrl: 'https://vpd.veidec.com/api',
    vpdWsUrl: 'wss://vpd.veidec.com/api',
    sentry: {
      enabled: true,
      dsn: 'https://760a570e9dfb486b8b173a6a994e37e6@o695437.ingest.sentry.io/5775616',
    },
  },
});


const environmentConfig = config[environment];

if (!environmentConfig)
  throw new Error(`Configuration not found for environment: ${environment}`);

export default environmentConfig;
