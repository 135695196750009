import { create as timesync } from 'timesync';
import config from '../config';
import moment from 'moment';

const ts = timesync({
  server: `${config.vpdApiUrl}/time`,
  repeat: 1,
});

const now = () => new Date(ts.now());
const sync = () => ts.sync();

const timeAgo = date => moment(date).from(now());

export {
  now,
  sync,
  timeAgo,
};
