import React, {
  memo,
  useEffect,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventContent from 'component/EventContent';
import { makeStyles } from '@material-ui/core/styles';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import useTranslation from 'hook/useTranslation';
import LanguageIcon from '@material-ui/icons/Language';
import EventIcon from '@material-ui/icons/Event';
import {
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import clsx from 'clsx';
import { updateUser } from '../../action/user';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.fc-header-toolbar': {
      position: 'relative'
    },
    '.fc-timegrid-slot': {
      cursor: 'pointer'
    },
    '.fc-timegrid-body': {
      display: 'flex',
      '& > div': {
        flexGrow: 1
      }
    }
  },
  instantEventWrapper: {
    display: 'inline-block',
    '& button': {
      textTransform: 'initial',
      fontWeight: 400,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      }
    }
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  selectedLanguage: {
    fontWeight: 600,
  },
}));

const LanguageSelection = () => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { t, setLanguage } = useTranslation();
  const { language: selectedLanguage, languages } = useSelector(state => state.translation);
  const dispatch = useDispatch();

  if (!selectedLanguage || languages.length === 0)
    return null;

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const onChange = language => {
    setLanguage(language);
    dispatch(updateUser({ language }));
    closeMenu();
  }

  const menuItems = languages.map(({ language, name }) =>
    <MenuItem key={language} onClick={() => onChange(language)}>
      <span className={clsx(language === selectedLanguage && classes.selectedLanguage)}>
        {name} ({language})
      </span>
    </MenuItem>
  );

  return (
    <>
      <IconButton
        onClick={openMenu}
        title={t('Change language')}
      >
        <LanguageIcon/>
      </IconButton>

      <Menu open={menuOpen}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        keepMounted>
        {menuItems}
      </Menu>
    </>
  );
};

const Calendar = ({ events, handleEventClick, onSelectEventDate, onOpenEventDialog }) => {
  const classes = useStyles();
  const params = new URLSearchParams(document.location.search);
  const isDev = params.get('dev');
  const selectedDate = params.get('selectedDate');
  const additionalParams = {};
  const { locale } = useSelector(state => state.translation);
  const { t } = useTranslation();

  if (selectedDate) {
    additionalParams.visibleRange = {
      start: moment(selectedDate).format(),
      end: moment(selectedDate).add(1, 'days').format(),
    }
  }

  useEffect(() => {
    const instantEventWrapper = document.createElement('div');
    instantEventWrapper.classList.add(classes.instantEventWrapper);
    document.querySelector('.fc-toolbar-chunk').appendChild(instantEventWrapper);

    const startTime = params.get('startTime');
    const endTime = params.get('endTime');

    if (startTime || endTime) {
      [...document.querySelectorAll(`.fc-timegrid-slot`)].forEach(el => {
        if (startTime && parseFloat(el.dataset.time.split(':').join('.')) < parseFloat(startTime.split(':').join('.'))) {
          el.parentElement.style.display = 'none';
        }
        if (endTime && parseFloat(el.dataset.time.split(':').join('.')) > parseFloat(endTime.split(':').join('.'))) {
          el.parentElement.style.display = 'none';
        }
      });
    }

    if (startTime || endTime) {
      const wrapperElement = document.querySelector('.fc-timegrid-slots');
      const height = wrapperElement.clientHeight;
      wrapperElement.firstElementChild.style.height = `${height}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEventContent = (eventInfo) => {
    const activeEvent = events.find(item => moment().isBetween(item.start, item.end));
    return (
      <EventContent
        isActive={activeEvent && activeEvent.id === eventInfo.event.id}
        eventInfo={eventInfo}
      />
    );
  };

  const renderAdditionalButtons = () => {
    const containerElement = document.querySelector(`.${classes.instantEventWrapper}`);
    if (containerElement) {
      return ReactDOM.createPortal(
        <>
          {
            isDev &&
            <IconButton
              onClick={onOpenEventDialog}
              title={t('Create event')}
            >
              <EventIcon/>
            </IconButton>
          }
          &nbsp;
          <LanguageSelection/>
        </>,
        containerElement
      );
    }
  }

  return (
    <>
      <FullCalendar
        locale={locale}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: !selectedDate ? 'prev,next today' : '',
          center: 'title',
          right: selectedDate ? '' : params.get('gridMode') || 'timeGridWeek,timeGridDay',
        }}
        buttonText={{
          today: t('today'),
          month: t('month'),
          week: t('week'),
          day: t('day'),
          list: t('list'),
        }}
        hiddenDays={params.get('hiddenDays') ? params.get('hiddenDays').split(',').map(Number) : []}
        height={'100%'}
        eventContent={renderEventContent}
        initialView={selectedDate ? 'timeGrid' : params.get('gridMode') ? params.get('gridMode').split(',')[0] : 'timeGridWeek'}
        // editable={true}
        // selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        nowIndicator={true}
        weekends={params.get('weekends') === 'true'}
        allDaySlot={false}
        events={events} // alternatively, use the `events` setting to fetch from a feed
        // select={this.handleDateSelect}
        // eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        dateClick={onSelectEventDate}
        // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
         eventAdd={function(){}}
         eventChange={function(){}}
         eventRemove={function(){}}
         */
        {...additionalParams}
      />
      {
        renderAdditionalButtons()
      }
    </>
  );
}

export default memo(Calendar);
