import config from '../config';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

const { sentry } = config;
const { enabled, dsn } = sentry;

const initSentry = () => {
  if (enabled)
    Sentry.init({
      dsn,
      integrations: [
        new Integrations.BrowserTracing(),
        new CaptureConsole({
          levels: ['error']
        }),
      ],
      tracesSampleRate: 1.0,
    });
};

export {
  initSentry,
};
