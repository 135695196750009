import React, { memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import {
  DialogActions,
  makeStyles
} from '@material-ui/core';
import { useInput } from 'react-hookedup';
import { useParams } from 'react-router';
import useTranslation from 'hook/useTranslation';

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  margin: {
    marginRight: theme.spacing(2)
  }
}));

const ParticipantDialog = ({ open, participant, isSeller, setAudio, onUpdateBuyer }) => {
  const classes = useStyles();
  const name = useInput(participant.displayName || '');
  const { participantId } = useParams();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
    >
      <DialogContent>
        <Typography variant={'body1'}>
          {isSeller ? t('Start presentation') : t('To enter the presentation, please type your name')}
        </Typography>
        {
          !isSeller && (
            <TextField
              className={classes.input}
              label={t('Name')}
              value={name.value}
              onChange={name.onChange}
            />
          )
        }
      </DialogContent>
      <DialogActions>
        {
          isSeller ? (
            <>
              <Button
                className={classes.margin}
                color="primary"
                onClick={() => setAudio(true)}
                startIcon={<MicIcon/>}
              >
                {t('With audio')}
              </Button>
              <Button
                color="primary"
                onClick={() => setAudio(false)}
                startIcon={<MicOffIcon/>}
              >
                {t('Without audio')}
              </Button>
            </>
          ) : (
            <Button
              disabled={!name.value}
              color="primary"
              onClick={() => onUpdateBuyer({ id: participantId, displayName: name.value })}
            >
              {t('JOIN')}
            </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
}

export default memo(ParticipantDialog);
