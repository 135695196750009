import React, { memo } from 'react';
import { Snackbar } from '@material-ui/core';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { setToast } from '../../action/toast';
import useTranslation from 'hook/useTranslation';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toast = () => {
  const { open, message, alertType, permanent } = useSelector((state) => state.toast);
  const dispatch = useDispatch();
  const params = {};
  const { t } = useTranslation();

  const onClose = () => dispatch(setToast({ open: false, permanent: false }))

  if (!permanent) {
    params.autoHideDuration = 3000;
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
      {...params}
    >
      <Alert
        onClose={onClose}
        severity={alertType}
      >
        {t(message)}
      </Alert>
    </Snackbar>
  )
}

export default memo(Toast);
