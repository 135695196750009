import 'date-fns';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from './component/common/Theme';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import reducer from './reducer';
import Routes from 'component/routes';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { sync } from './util/time';
import { initSentry } from './util/sentry';
import './App.css'

sync();
initSentry();

const store = createStore(
    reducer,
    applyMiddleware(thunk, logger),
);

function App() {
    return (
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={Theme}>
                    <CssBaseline/>
                    <Routes />
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </Provider>
    );
}

export default App;
