import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import config from 'config';
import useTranslation from 'hook/useTranslation';


const { vpdApiUrl } = config;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh'
  },
  title: {}
}));

const UnauthorizedPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant={'h4'}>{t('You are not authorized to access this page.')}</Typography>
      <Typography
        variant={'h5'}
        dangerouslySetInnerHTML={{
          __html: t('Use the %{link} to authenticate first.', { link: `<a href="${vpdApiUrl}/swagger-ui/#/user/post_user_sign_in">API</a>` })
        }}/>
    </div>
  )
}

export default UnauthorizedPage;
