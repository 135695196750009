import React, { memo } from 'react';
import {
  Menu,
  MenuItem
} from '@material-ui/core';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import useTranslation from 'hook/useTranslation';


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1)
  },
  confirm: {
    color: theme.palette.primary.main
  }
}));

const ConfirmPopup = ({ anchorEl, confirmLabel, onConfirm, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();


  return (
    <Menu
      id="confirm-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={onConfirm}><CheckIcon className={clsx(classes.icon, classes.confirm)} fontSize={'small'}/>{t(confirmLabel || 'Confirm')}</MenuItem>
      <MenuItem onClick={onClose}><CloseIcon className={classes.icon} fontSize={'small'}/>{t('Cancel')}</MenuItem>
    </Menu>
  )
}

export default memo(ConfirmPopup);
