import { REFRESH_DEVICES, UPDATE_INPUT_DEVICE_ID, UPDATE_MUTED, UPDATE_OUTPUT_DEVICE_ID } from '../action/settings';

const LS_AUDIO_INPUT_DEVICE = 'audio.inputDeviceId';
const LS_AUDIO_OUTPUT_DEVICE = 'audio.outputDeviceId';

const initialState = {
  audio: {
    devices: [],
    inputDeviceId: localStorage.getItem(LS_AUDIO_INPUT_DEVICE) || 'default',
    outputDeviceId: localStorage.getItem(LS_AUDIO_OUTPUT_DEVICE) || 'default',
    muted: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_DEVICES:
      const devices = action.devices;
      const inputFound = devices.find(device => device.deviceId === state.inputDeviceId);
      if (!inputFound) {
        localStorage.setItem(LS_AUDIO_INPUT_DEVICE, 'default');
      }
      const outputFound = devices.find(device => device.deviceId === state.outputDeviceId);
      if (!outputFound) {
        localStorage.setItem(LS_AUDIO_OUTPUT_DEVICE, 'default');
      }
      return {
        ...state,
        audio: {
          ...state.audio,
          devices: action.devices,
          inputDeviceId: inputFound ? state.inputDeviceId : 'default',
          outputDeviceId: outputFound ? state.outputDeviceId : 'default',
        },
      };

    case UPDATE_INPUT_DEVICE_ID:
      localStorage.setItem(LS_AUDIO_INPUT_DEVICE, action.deviceId);
      return { ...state, audio: { ...state.audio, inputDeviceId: action.deviceId } };

    case UPDATE_OUTPUT_DEVICE_ID:
      localStorage.setItem(LS_AUDIO_OUTPUT_DEVICE, action.deviceId);
      return { ...state, audio: { ...state.audio, outputDeviceId: action.deviceId } };

    case UPDATE_MUTED:
      return { ...state, audio: { ...state.audio, muted: action.muted } };

    default:
      return state;
  }
}

export default reducer;
