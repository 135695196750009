import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.fc-v-event': {
      backgroundColor: 'transparent'
    },
    '.fc-timegrid-event.fc-v-event': {
      border: 'none',
      '& button': {
        display: 'flex',
        padding: '7px 0',
        borderRadius: 3
      }
    }
  },
  eventContent: {
    overflow: 'hidden',
    padding: 0,
    width: '100%',
    height: '100%',
    textTransform: 'inherit',
    '& > span:first-child': {
      paddingLeft: 5,
      justifyContent: 'flex-start'
    }
  },
  activeEvent: {
    color: 'white',
    '&::before': {
      content: '""',
      display: 'block',
      width: 5,
      height: 5,
      left: 4,
      position: 'absolute',
      backgroundColor: 'yellow',
      borderRadius: '50%'
    },
    '& > span:first-child': {
      paddingLeft: 12
    }
  },
  pending: {
    backgroundColor: '#f9a701',
    borderColor: '#f9a701',
    '&:hover': {
      backgroundColor: '#d99306',
      borderColor: '#d99306'
    },
    '& span': {
      color: 'white'
    }
  },
  pastEvent: {
    backgroundColor: theme.palette.past.main,
    '&:hover': {
      backgroundColor: theme.palette.past.dark,
    }
  }
}));

const EventContent = ({ eventInfo, isActive }) => {
  const classes = useStyles();
  const additionalParams = {};

  if (isActive) {
    additionalParams.color = 'primary';
  }

  return (
    <Button
      variant={'contained'}
      size={'small'}
      className={clsx(`event-${eventInfo.event.id}`, classes.eventContent, {
        [classes.pending]: eventInfo.event.extendedProps.status === 'pending',
        [classes.activeEvent]: isActive,
        [classes.pastEvent]: eventInfo.isPast,
      })}
      {...additionalParams}
    >
      {`${eventInfo.timeText} ${eventInfo.event.title}`}
    </Button>
  )
};

EventContent.propTypes = {
  isActive: PropTypes.bool,
  eventInfo: PropTypes.shape({
    timeText: PropTypes.string,
    event: PropTypes.shape({
      title: PropTypes.string
    })
  }).isRequired
}

export default memo(EventContent);
