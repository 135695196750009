import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {
  Link
} from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import useTranslation from 'hook/useTranslation';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  pushRight: {
    marginLeft: 'auto'
  }
}));

const hideHeader = true;

const Header = () => {
  const classes = useStyles();
  const { t } = useTranslation();


  if (hideHeader)
    return null;
  // if (location.pathname.startsWith('/presentation')) return null;

  // eslint-disable-next-line
  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon/>
        </IconButton>
        {/*<Typography variant="h6" className={classes.title}>*/}
        {/*    News*/}
        {/*</Typography>*/}
        <Button component={Link} color="inherit" className={classes.pushRight} to="/seller-calendar">{t('Seller Calendar')}</Button>
        <Button component={Link} color="inherit" to="/buyer-calendar">{t('Buyer Calendar')}</Button>
      </Toolbar>
    </AppBar>
  );
}

export default memo(Header)
