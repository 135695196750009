import { combineReducers } from 'redux';
import user from './user';
import toast from './toast';
import demo from './demo';
import settings from './settings';
import translation from './translation';

const reducers = combineReducers({
  user,
  toast,
  demo,
  settings,
  translation,
});

export default reducers;
