import React, {
  memo,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PageRoot from 'component/pageRoot';
import EventDetailsPopover from 'component/eventDetailsPopover';
import Calendar from 'component/calendar';
import Paper from '@material-ui/core/Paper';
import CreateEventDialog from 'component/createEventDialog';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import {
  getDemos,
  deleteDemo
} from 'action/demo';
import authorize from '../../util/higherOrderComponents/auth';
import { fetchLanguages } from '../../action/translation';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.highlighted-slot': {
      border: '3px rgb(0, 150, 57) solid',
      borderRadius: '5px',
    },
    '.highlighted-slot .MuiButton-contained': {
      backgroundColor: 'rgba(0, 150, 57, 0.01)',
    },
  },
  root: {
    '& table.fc-scrollgrid-sync-table': {
      '& td': {
        cursor: 'pointer'
      }
    },
    '& table.fc-col-header': {
      backgroundColor: '#efefef',
      '& td': {
        cursor: 'pointer'
      }
    }
  },
  eventContent: {
    padding: 0,
    width: '100%',
    height: '100%',
    textTransform: 'inherit',
    '& > span:first-child': {
      paddingLeft: 5,
      justifyContent: 'flex-start'
    }
  },
  activeEvent: {
    color: 'white'
  },
  buttonsWrapper: {
    marginBottom: theme.spacing(2),
    '& > button': {
      marginRight: theme.spacing(2),
    }
  },
  paper: {
    height: '100vh',
    padding: theme.spacing(2)
  },
  menuItem: {
    display: 'flex',
    cursor: 'default',
    '& > button': {
      marginLeft: 'auto'
    },
    '& > span': {
      marginRight: 15
    }
  }
}));

const SellerCalendarPage = () => {
  const classes = useStyles();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventDialog, setShowEventDialog] = useState(false);
  const [isEditingEvent, setIsEditingEvent] = useState(false);
  const [selectedDateForCreateEvent, setSelectedDateForCreateEvent] = useState(null);
  const dispatch = useDispatch();
  const { demos } = useSelector(state => state.demo);
  const [highlightSlots, setHighlightSlots] = useState([]);

  const params = new URLSearchParams(document.location.search);
  const isDev = params.get('dev');

  useEffect(() => {
    dispatch(getDemos());
    dispatch(fetchLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Passed down from parent iframe
    const onParentMessage = event => {
      if (!event.data || typeof event.data !== 'string')
        return;
      const data = JSON.parse(event.data);
      const { type } = data; // Event type
      switch (type) {
        case 'highlightSlot':
          const { startAt, endAt } = data;
          const start = new Date(startAt);
          const end = new Date(endAt);
          setHighlightSlots([{
            start,
            end,
            display: 'background',
            classNames: ['highlighted-slot'],
          }])
          break;
        case 'clearHighlightedSlot':
          setHighlightSlots([]);
          break;
        case 'fetchDemos':
          dispatch(getDemos());
          break;
        default:
          console.error('Unsupported message from child iframe', data);
      }
    };
    window.addEventListener('message', onParentMessage, false);
    return () => window.removeEventListener('message', onParentMessage);
  }, [dispatch]);

  const postSlotClickedMessage = clickedStartAt => {
    if (window.self !== window.top) { // i.e. in iframe/frame
      const selectedDemos = demos.filter(({ startAt, endAt }) =>
        clickedStartAt >= new Date(startAt) && clickedStartAt <= new Date(endAt));
      window.top.postMessage(JSON.stringify({
        type: 'slotClicked',
        clickedStartAt,
        demos: selectedDemos,
      }), '*');
    }
  }
  const handleEventClick = e => {
    setSelectedEvent(e.event);
    postSlotClickedMessage(new Date(e.event.start));
  }

  const onSelectEventDate = (data) => {
    if (isDev && moment(data.date).isBetween(moment(data.date).hour(6), moment(data.date).hour(18), 'hour')) {
      setSelectedDateForCreateEvent(data.date.toString());
      onOpenFutureEventDialog();
    }
    postSlotClickedMessage(new Date(data.date));
  }

  const onRemoveEvent = () => {
    dispatch(deleteDemo(selectedEvent.id));
    setSelectedEvent(null);
  }

  const onEdit = () => {
    setIsEditingEvent(true);
  }

  const onOpenFutureEventDialog = () => {
    setShowEventDialog(true);
  }

  const onCloseEventDialog = () => {
    setSelectedEvent(null);
    setShowEventDialog(false);
    setIsEditingEvent(false);
    setSelectedDateForCreateEvent(null);
  }

  return (
    <PageRoot noContainer={true}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Calendar
            events={[...demos, ...highlightSlots]}
            handleEventClick={handleEventClick}
            onSelectEventDate={onSelectEventDate}
            onOpenEventDialog={onOpenFutureEventDialog}
          />
        </Paper>
        <EventDetailsPopover
          open={!!selectedEvent && !isEditingEvent}
          selectedEvent={selectedEvent}
          onEdit={onEdit}
          onClose={() => setSelectedEvent(null)}
          onRemove={onRemoveEvent}
          isDev={isDev}
        />
      </div>
      <CreateEventDialog
        selectedDateForCreateEvent={selectedDateForCreateEvent}
        open={showEventDialog || isEditingEvent}
        selectedEvent={selectedEvent}
        onClose={onCloseEventDialog}
      />
    </PageRoot>
  )
}

export default authorize(memo(SellerCalendarPage));
