import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';
import SellerCalendarPage from 'page/sellerCalendarPage';
import BuyerCalendarPage from 'page/buyerCalendarPage';
import PresentationPage from 'page/presentationPage';
import UnauthorizedPage from '../../page/unauthorizedPage';
import Header from 'component/header';
import Toast from 'component/toast';

const Routes = () => {
  return (
    <Router>
      <Header/>
      <Toast />
      <Switch>
        <Route path="/buyer-calendar">
          <BuyerCalendarPage/>
        </Route>
        <Route path={'/presentation/:demoId/:participantId'}>
          <PresentationPage/>
        </Route>
        <Route path={'/unauthorized'}>
          <UnauthorizedPage/>
        </Route>
        <Route path="/" exact={true}>
          <SellerCalendarPage/>
        </Route>
      </Switch>
    </Router>
  )
}

export default Routes;
