import {
  FETCHED_TRANSLATIONS,
  FETCHED_LANGUAGES,
  CHANGED_TRANSLATION_LANGUAGE,
} from '../action/translation';
import Polyglot from 'node-polyglot';
import { SIGNED_IN } from '../action/user';
import { translationLocaleFromDemoLanguage } from '../../../../common/src/util/translation';

const initialState = {
  languages: [],
  language: 'en',
  locale: 'en',
  polyglotForLanguage: {},
};

const reducer = (state = initialState, action) => {
  switch (action?.type) {
    case FETCHED_TRANSLATIONS: {
      const { language, locale, translations } = action;
      const { polyglotForLanguage } = state;
      const polyglot = new Polyglot({ phrases: translations, locale });
      polyglot.t.bind(polyglot);
      polyglotForLanguage[language] = polyglot;
      return {
        ...state,
        locale,
        language,
        polyglotForLanguage,
      };
    }
    case FETCHED_LANGUAGES: {
      const { languages } = action;
      return {
        ...state,
        languages,
      };
    }
    case CHANGED_TRANSLATION_LANGUAGE: {
      const { language } = action;
      const locale = translationLocaleFromDemoLanguage(language);
      return {
        ...state,
        language,
        locale,
      };
    }
    case SIGNED_IN: {
      const { language } = action.user;
      const locale = translationLocaleFromDemoLanguage(language);
      return {
        ...state,
        language,
        locale,
      };
    }
    default:
      return state;
  }
};

export default reducer;
