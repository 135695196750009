export const SET_TOAST = 'SET_TOAST';
export const SUCCESS_TYPE = 'success';
export const ERROR_TYPE = 'error';
export const WARNING_TYPE = 'warning';
export const INFO_TYPE = 'info';

const setData = data => ({
  type: SET_TOAST,
  data,
});

export const setToast = (data) => dispatch => {
  dispatch(setData(data));
}