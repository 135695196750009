import React, {
  memo,
  useEffect,
  useState
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PageRoot from 'component/pageRoot';
import BuyerCalendarSideDetails from 'component/buyerCalendarSideDetails';
import BuyerDateTimeSelection from 'component/buyerDateTimeSelection';
import moment from 'moment';
import { fetchLanguages } from '../../action/translation';
import {
  useDispatch,
} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  sidebar: {
    flexGrow: 1,
    marginRight: theme.spacing(2)
  },
  dateSelection: {
    flexGrow: 8
  }
}));

const calendarData = {
  availableDays: [
    moment(),
    moment().add(1, 'day')
  ],
  availableTime: ['08 - 09 am', '10 - 11 am', '13 - 14 pm', '16 - 17 pm']
}

const BuyerCalendarPage = () => {
  const classes = useStyles();
  const [date, setDate] = useState();
  const [selectedTime, setSelectedTime] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageRoot className={classes.root}>
      <BuyerCalendarSideDetails
        className={classes.sidebar}
        selectedTime={selectedTime}
        date={date}
      />
      <BuyerDateTimeSelection
        className={classes.dateSelection}
        date={date}
        calendarData={calendarData}
        selectedTime={selectedTime}
        setDate={setDate}
        setSelectedTime={setSelectedTime}
      />
    </PageRoot>
  )
};

export default memo(BuyerCalendarPage);
