import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import {
  darken,
  fade
} from '@material-ui/core/styles/colorManipulator';

const COLOR = 'rgb(0,150,57)';

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: COLOR,
      light: fade(COLOR, 0.1),
      dark: darken(COLOR, 0.1)
    },
    secondary: {
      main: '#da291c',
    },
    action: {
      main: 'rgba(0, 0, 0, 0.54)',
      darken: 'rgba(0, 0, 0, 0.6)'
    },
    past: {
      main: '#f7f7f7',
      light: fade('#f7f7f7', 0.1),
      dark: darken('#f7f7f7', 0.1)
    },
    light: {
      main: 'rgba(0,0,0,0.03)'
    },
    error: {
      main: red.A400,
      light: fade(red.A400, 0.1),
    },
    background: {
      default: '#efefef',
    },
    text: {
      secondary: '#5f6368'
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '10px 10px',
      },
    },
    MuiAccordionSummary: {
      root: {
        '&$expanded': {
          marginBottom: -5,
        },
        width: '99%',
        marginBottom: -5,
      },
    }
  },
});

export default Theme;
