import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { connect } from 'twilio-video';
import useLocalStorage from '../../hook/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { refreshDevices, updateMuted } from '../../action/settings';
import { ERROR_TYPE, setToast, SUCCESS_TYPE } from '../../action/toast';
import moment from 'moment';
import { Fab, Tooltip, Zoom } from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import useTranslation from '../../hook/useTranslation';

const debugging = false;
const clog = (...args) => debugging ? console.log.apply(console, args) : () => {};

function AudioTrack({ track, sinkId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const audioEl = useRef();

  useEffect(() => {
    clog('mounting track');
    audioEl.current = track.attach();
    audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioEl.current);
    return () => track.detach().forEach(el => el.remove());
  }, [track]);

  useEffect(() => {
    if (audioEl?.current?.setSinkId) {
      (async () => {
        try {
          clog('setting track sink');
          await audioEl.current.setSinkId(sinkId);
        } catch (err) {
          console.error(err);
          dispatch(setToast({ open: true, message: t('Failed switching audio devices'), alertType: ERROR_TYPE }));
        }
      })();
    }
    // eslint-disable-next-line
  }, [track, sinkId]);

  return null;
}

function useToken(demoId, identity) {
  const [tokens, setTokens] = useLocalStorage('twilio.tokens', []);
  const token = tokens.find(token => token.demoId === demoId && token.identity === identity);

  useEffect(() => {
    const interval = setInterval(() => {
      const nonExpiredTokens = tokens.filter(token => moment().isSameOrBefore(moment(token.expiresAt)));
      if (tokens.length !== nonExpiredTokens.length) {
        setTokens(nonExpiredTokens);
      }
    }, 60 * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const setToken = useCallback((token, expiresAt) => {
    setTokens([...tokens, { demoId, identity, value: token, expiresAt }]);
  }, [demoId, identity, tokens, setTokens]);

  return [token?.value, setToken];
}

const mute = publication => publication.track.disable();
const unmute = publication => publication.track.enable();

export default function AudioRoom({ demoId, identity, hasAudio }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [room, setRoom] = useState();
  const [token, setToken] = useToken(demoId, identity);
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    dispatch(refreshDevices());
    const onDeviceChange = () => dispatch(refreshDevices());
    navigator.mediaDevices.addEventListener('devicechange', onDeviceChange);
    return () => navigator.mediaDevices.removeEventListener('devicechange', onDeviceChange);
  }, [dispatch]);

  const addTrack = useCallback(track => {
    clog('setting tracks');
    setTracks([...tracks.filter(t => t.sid !== track.sid), track]);
  }, [tracks, setTracks]);

  const subscribe = useCallback(participant => {
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        clog('adding track');
        addTrack(publication.track);
      }
    });
    participant.on('trackSubscribed', track => {
      clog('adding track on subscribed');
      addTrack(track);
    });
  }, [addTrack]);

  const [fetchingToken, setFetchingToken] = useState(false);

  useEffect(() => {
    if (hasAudio && !token) {
      if (!fetchingToken) {
        setFetchingToken(true);
        (async () => {
          try {
            const res = await axios({
              method: 'post',
              url: `${config.vpdApiUrl}/webrtc/token`,
              data: { demoId, identity }
            });
            const { token: _token, expiresAt } = res.data;
            setToken(_token, expiresAt);
          } catch (err) {
            console.error(err);
            dispatch(setToast({ open: true, message: t('Failed connecting audio'), alertType: ERROR_TYPE }));
          } finally {
            setFetchingToken(false);
          }
        })();
      }
    }
    // eslint-disable-next-line
  }, [demoId, identity, token, setToken, hasAudio]);

  const { inputDeviceId, outputDeviceId } = useSelector(state => state.settings.audio);
  const [connecting, setConnecting] = useState(false);

  useEffect(() => {
    const onDisconnected = (room, error) => {
      clog('disconnected');
      if (error) {
        dispatch(setToast({ open: true, message: t('Audio disconnected'), alertType: ERROR_TYPE }));
        switch (error.code) {
          case 20104:
            console.error('Signaling reconnection failed due to expired AccessToken!');
            break;
          case 53000:
            console.error('Signaling reconnection attempts exhausted!');
            break;
          case 53204:
            console.error('Signaling reconnection took too long!');
            break;
          default:
            console.info('Disconnected manually');
            break;
        }
      } else {
        setRoom(null);
      }
    }

    if (hasAudio && token) {
      if (!connecting) {
        setConnecting(true);
        (async () => {
          let _room;
          try {
            if (room) {
              clog('disconnecting');
              room.disconnect();
            }
            _room = await connect(token, {
              audio: { advanced: [{ deviceId: inputDeviceId }] },
              video: false,
              enableDscp: true,
            });
            clog('connected');
            _room.on('disconnected', onDisconnected);
            _room.on('reconnecting', error => {
              clog('reconnecting');
              dispatch(setToast({ open: true, message: t('Reconnecting audio'), alertType: ERROR_TYPE }));
              switch (error.code) {
                case 53001:
                  console.info('Reconnecting your signaling connection!', error.message);
                  break;
                case 53405:
                  console.info('Reconnecting your media connection!', error.message);
                  break;
                default:
                  console.info('Reconnecting due to unkown reasons', error);
                  break;
              }
              _room.on('reconnected', () => {
                clog('reconnected');
                console.info('Reconnected audio!', error.message);
                dispatch(setToast({ open: true, message: t('Audio reconnected'), alertType: SUCCESS_TYPE }));
              });
              _room.on('participantReconnecting', remoteParticipant => {
                clog('reconnecting participant');
                console.info(`${remoteParticipant.identity} is reconnecting the signaling connection to the Room!`);
                dispatch(setToast({
                  open: true,
                  message: t(`Participant ${remoteParticipant.identity} reconnecting audio`),
                  alertType: ERROR_TYPE
                }));
              });
            });
            setRoom(_room);
          } catch (err) {
            console.error(err);
            dispatch(setToast({ open: true, message: t('Failed connecting audio'), alertType: ERROR_TYPE }));
          } finally {
            setConnecting(false);
            if (_room?.state === 'connected' && !room) {
              dispatch(setToast({
                open: true,
                message: t('Audio connected'),
                alertType: SUCCESS_TYPE
              }));
            }
          }
        })();
      }
    } else {
      if (room?.state === 'connected') {
        clog('disconnecting due to audio turned off');
        room.disconnect();
      }
    }

    return () => {
      if (room?.state === 'connected') {
        room.off('disconnected', onDisconnected);
        clog('disconnecting due to leaving')
        room.disconnect();
      }
    }
    // eslint-disable-next-line
  }, [token, room, inputDeviceId, hasAudio]);

  useEffect(() => {
    (async () => {
      if (room) {
        for (const participant of room.participants.values()) {
          clog(`subscribing to ${participant}`);
          subscribe(participant);
        }
        room.on('participantConnected', participant => {
          clog(`subscribing to ${participant} on connected`);
          subscribe(participant);
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  const hasAudioTrack = room?.state === 'connected';
  const muted = useSelector(state => state.settings.audio.muted);
  const toggleMuted = () => dispatch(updateMuted(!muted));
  const initialRender = useRef(true);

  useEffect(() => {
    if (!room) {
      return;
    }
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    const operation = muted ? mute : unmute;
    room.localParticipant.audioTracks.forEach(operation);
  }, [room, muted]);

  return (
    <>
      <Tooltip
        title={hasAudioTrack ? (muted ? t('Turn on microphone') : t('Turn off microphone')) : t('Audio not connected')}
        TransitionComponent={Zoom}
      >
          <span>
            <Fab
              onClick={toggleMuted}
              disabled={!hasAudioTrack}
              color={muted ? 'secondary' : 'default'}
              data-cy-audio-toggle
            >
              {muted ? <MicOffIcon/> : <MicIcon/>}
            </Fab>
          </span>
      </Tooltip>
      {tracks.map(track => (<AudioTrack key={track.sid} track={track} sinkId={outputDeviceId}/>))}
    </>
  );
}
