import {
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
} from '@material-ui/core';
import AudioRoom from '../audioRoom';
import React, {
  useState
} from 'react';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {
  updateInputDeviceId,
  updateOutputDeviceId
} from '../../action/settings';
import ConfirmPopup from 'component/confirmPopup';
import LanguageIcon from '@material-ui/icons/Language';
import countries from '../../../../../common/src/util/countries.json';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import PersonIcon from '@material-ui/icons/Person';
import Badge from '@material-ui/core/Badge';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import Typography from '@material-ui/core/Typography';
import GroupIcon from '@material-ui/icons/Group';
import useTranslation from 'hook/useTranslation';

const useStyles = makeStyles(theme => ({
  '@global': {
    '#root': {
      overflow: 'hidden',
    }
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: '1',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    overflow: 'hidden',
  },
  menu: {
    float: 'right',
  },
  left: {
    '@media (min-width: 600px)': {
      flexGrow: 1,
      width: '50%',
    },
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'right',
  },
  center: {},
  right: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
    width: '50%',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  selectElement: {
    textAlign: 'start',
  },
  languageMenuItem: {
    paddingRight: theme.spacing(5),
  },
  languageIcon: {
    marginRight: theme.spacing(1),
    fontSize: 16,
  },
  languageIconHidden: {
    visibility: 'hidden',
  },
  productMenuItem: {
    textAlign: 'start',
  },
  presentationSelect: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  participants: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  participant: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
  participantName: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  participantMic: {
    border: `1px solid ${theme.palette.background.default}`,
  },
  green: {
    paddingLeft: 10,
    color: theme.palette.primary.main,
  },
  divider: {
    width: theme.spacing(1),
  },
  presentationSelectItem: {
    paddingLeft: theme.spacing(3),
  },
  presentationSelectGroup: {
    fontWeight: theme.typography.fontWeightMedium,
  }
}));

const mapToAudioOption = device => ({ value: device.deviceId, label: device.label });
const audioOptionLabel = (item, index) => {
  if (item.label) return item.label;
  // android (both webview and chromium-based browsers) enumerate devices with label being ''
  if (item.value === 'default') return 'Default';
  return `Device ${index}`;
}

const InputDeviceSelect = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { devices, inputDeviceId } = useSelector(state => state.settings.audio);
  const inputItems = devices.filter(device => device.kind === 'audioinput').map(mapToAudioOption);
  const { t } = useTranslation();

  const defaultDevice = t('System Default Microphone Device');
  if (!inputItems.find(({ value }) => value === inputDeviceId))
    inputItems.unshift({ value: inputDeviceId, label: defaultDevice });

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="audio-input-select">{t('Microphone')}</InputLabel>
      {
        inputItems.length > 0 ?
          (
            <Select
              className={classes.selectElement}
              labelId="audio-input-select-label"
              id="audio-input-select"
              value={inputDeviceId}
              onChange={e => dispatch(updateInputDeviceId(e.target.value))}>
              {
                inputItems.map((item, index) => (
                  <MenuItem key={item.value} value={item.value}>{audioOptionLabel(item, index)}</MenuItem>
                ))
              }
            </Select>
          )
          :
          (
            <Select
              className={classes.selectElement}
              labelId="audio-input-select-label"
              id="audio-input-select"
              value={defaultDevice}>
              <MenuItem value={defaultDevice}>{defaultDevice}</MenuItem>
            </Select>
          )
      }
    </FormControl>
  );
};

const OutputDeviceSelect = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { devices, outputDeviceId } = useSelector(state => state.settings.audio);
  const outputItems = devices.filter(device => device.kind === 'audiooutput').map(mapToAudioOption);
  const { t } = useTranslation();

  const defaultDevice = t('System Default Speaker Device');
  if (!outputItems.find(({ value }) => value === outputDeviceId))
    outputItems.unshift({ value: outputDeviceId, label: defaultDevice });

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="audio-output-select">{t('Speaker')}</InputLabel>
      {outputItems.length > 0 ?
        (
          <Select
            className={classes.selectElement}
            labelId="audio-output-select-label"
            id="audio-output-select"
            value={outputDeviceId}
            onChange={e => dispatch(updateOutputDeviceId(e.target.value))}>
            {
              outputItems.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>{audioOptionLabel(item, index)}</MenuItem>
              ))
            }
          </Select>
        )
        :
        (
          <Select
            className={classes.selectElement}
            labelId="audio-output-select-label"
            id="audio-output-select"
            value={defaultDevice}>
            <MenuItem value={defaultDevice}>{defaultDevice}</MenuItem>
          </Select>
        )
      }
    </FormControl>
  );
};

const PresentationSelect = (
  { productShortName, products, setProductShortName, demo }
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { productsShortNames: topShortNames } = demo;

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  if (!productShortName || !products?.length)
    return null;

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const onChange = e => {
    setProductShortName(e.target.value);
    closeMenu();
  }

  const menuItem = ({ shortName, name, id }) => (
    <MenuItem key={id} value={shortName} className={classes.presentationSelectItem}>
      <b>{shortName}</b>&nbsp; - &nbsp;{name}
    </MenuItem>
  );
  const topMenuItems = topShortNames
    .map(topShortName => products.find(({ shortName }) => shortName === topShortName))
    .map(menuItem);
  const bottomMenuItems = products
    .filter(({shortName}) => !topShortNames.includes(shortName))
    .map(menuItem);

  const menuItems = [
    <MenuItem key={-1} disabled className={classes.presentationSelectGroup}>{t('Selected')}</MenuItem>,
    ...topMenuItems,
    <MenuItem key={-2} disabled className={classes.presentationSelectGroup}>{t('Other')}</MenuItem>,
    ...bottomMenuItems,
  ];

  return (
    <>
      <Tooltip title={`${t('Presentation')} (${productShortName})`}>
        <IconButton onClick={openMenu}>
          <ShopTwoIcon/>
        </IconButton>
      </Tooltip>
      <Menu open={menuOpen}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        keepMounted>
        <MenuItem>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="presentation-select">{t('Presentation')}</InputLabel>
            <Select
              className={classes.selectElement}
              id="presentation-select"
              value={productShortName}
              onChange={onChange}>
              {menuItems}
            </Select>
          </FormControl>
        </MenuItem>
      </Menu>
    </>
  );
};

const LanguagesSelect = (
  { languageCode, setLanguageCode, languagesCodes }
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  if (!languagesCodes?.length)
    return null;

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const onChange = lang => () => {
    setLanguageCode(lang);
    closeMenu();
  }

  // If previously selected language code does not exist in the changed presentation,
  // then attempt to set it to SE, EN or the first value.
  if (!languagesCodes.includes(languageCode)) {
    if (languagesCodes.includes('SE'))
      setLanguageCode('SE');
    else if (languagesCodes.includes('EN'))
      setLanguageCode('EN');
    else
      setLanguageCode(languagesCodes[0]);
  }

  return (
    <>

      <Tooltip title={`${t('Language')} (${languageCode})`}>
        <IconButton onClick={openMenu}>
          <LanguageIcon/>
        </IconButton>
      </Tooltip>

      <Menu open={menuOpen}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        keepMounted>
        {
          languagesCodes.map(lang => (
            <MenuItem key={lang} onClick={onChange(lang)} className={classes.languageMenuItem}>
              <CheckIcon
                className={clsx(
                  classes.languageIcon,
                  languageCode !== lang && classes.languageIconHidden)}
              />
              {countries[lang] ? `${lang} - ${countries[lang]}` : lang}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
};

const Settings = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { t } = useTranslation();

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <>
      <Tooltip title={t('Settings')}>
        <IconButton onClick={openMenu}>
          <SettingsIcon/>
        </IconButton>
      </Tooltip>
      <Menu open={menuOpen}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        keepMounted>
        <MenuItem>
          <OutputDeviceSelect/>
        </MenuItem>
        <MenuItem>
          <InputDeviceSelect/>
        </MenuItem>
      </Menu>
    </>
  );
};

const Participants = ({
  participantsState,
  isSeller,
  setParticipantIsMuted,
  hasAudio,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  if (!participantsState)
    return null;

  const { participants } = participantsState;
  const connectedCount = participants.filter(({ isConnected }) => isConnected).length;

  return (
    <>
      <Tooltip title={t('Participants')}>
        <IconButton onClick={openMenu}>
          <Badge badgeContent={(
            <p>
              <span className={classes.green}>{connectedCount}</span>
              <span>/{participants.length}</span>
            </p>
          )}>
            <GroupIcon/>
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu open={menuOpen}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        keepMounted>
        {participants.map(({
          id,
          displayName,
          email,
          isConnected,
          isMuted,
        }) => (
          <MenuItem key={id} className={classes.participant}>
            <Tooltip title={isConnected ? t('Connected') : t('Disconnected')}>
              <Badge
                variant="dot"
                color={isConnected ? 'primary' : 'error'}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}>
                <PersonIcon/>
              </Badge>
            </Tooltip>
            <Typography className={classes.participantName}>{displayName || email}</Typography>

            <IconButton
              onClick={() => setParticipantIsMuted(id, !isMuted)}
              className={clsx(isSeller && classes.participantMic)}
              disabled={!isSeller || !hasAudio}
            >
              <Tooltip title={isMuted ? t('Turn on microphone') : t('Turn off microphone')}>
                {
                  isMuted || !hasAudio ?
                    <MicOffIcon color={hasAudio && isConnected ? 'error' : 'disabled'} fontSize="small"/> :
                    <MicIcon color={hasAudio && isConnected ? 'primary' : 'disabled'} fontSize="small"/>
                }
              </Tooltip>
            </IconButton>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default function PresentationFooter(
  {
    demo,
    participant,
    hasAudio,

    // Language selection
    languageCode,
    setLanguageCode,
    languagesCodes,

    // Presentation selection
    productShortName,
    setProductShortName,
    products,

    participantsState,
    setParticipantIsMuted,
    onEndDemo
  }
) {
  const [confirmMenuAnchor, setConfirmMenuAnchor] = useState();
  const classes = useStyles();
  const { t } = useTranslation();

  if (!demo || !participant)
    return null;

  const isSeller = participant?.isSeller;

  const divider = <div className={classes.divider}/>;

  return (
    <Paper className={classes.footer} square>

      <div className={classes.left}>
      </div>

      <div className={classes.center}>
        <AudioRoom demoId={demo.id} identity={participant.id} hasAudio={hasAudio}/>
      </div>

      <div className={classes.right}>

        <Participants
          participantsState={participantsState}
          isSeller={isSeller}
          setParticipantIsMuted={setParticipantIsMuted}
          hasAudio={hasAudio}
        />

        {divider}

        {isSeller && <PresentationSelect
          productShortName={productShortName}
          setProductShortName={setProductShortName}
          products={products}
          demo={demo}
        />}

        {divider}

        {isSeller && <LanguagesSelect
          languageCode={languageCode}
          setLanguageCode={setLanguageCode}
          languagesCodes={languagesCodes}
        />}

        {divider}

        {
          isSeller && (
            <>
              <Tooltip title={t('End demo')}>
                <IconButton
                  onClick={(e) => setConfirmMenuAnchor(e.currentTarget)}
                >
                  <CloseIcon/>
                </IconButton>
              </Tooltip>
              <ConfirmPopup
                anchorEl={confirmMenuAnchor}
                confirmLabel={t('End demo')}
                onConfirm={() => {
                  onEndDemo();
                  setConfirmMenuAnchor(null);
                }}
                onClose={() => setConfirmMenuAnchor(null)}
              />
            </>
          )
        }

        {divider}

        <Settings/>

      </div>

    </Paper>
  );
}
