import React, { memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  DialogActions,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import useTranslation from 'hook/useTranslation';

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  margin: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleIcon: {
    marginRight: theme.spacing(1),
  }
}));

const ParticipantDisconnectedDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        <div className={classes.title}>
          <WarningIcon color="error" className={classes.titleIcon}/>
          <Typography component="div" variant="h6">{t('One of the participants has disconnected')}</Typography>
        </div>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          {t('This message will automatically close once the participant is back online')}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.margin}
          color="primary"
          onClick={() => setOpen(false)}
        >
          {t('Ignore')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ParticipantDisconnectedDialog);
