/* eslint-disable no-console */
import EventEmitter from 'eventemitter3';
import Status from './status';
import Event from './event';
import { deepCopy } from '../../../../../common/src/util/object';

class Handler extends EventEmitter {

  constructor(url, debug = false) {
    super();
    this._url = url;
    this._debug = debug;
    this._reconnectAttempt = 0;
    this._status = Status.DISCONNECTED;
    this._ws = undefined;
    this._message = undefined;
    this._avoidReconnect = false;
  }

  connect() {
    if (this._status === Status.CONNECTED) return;
    if (this._status === Status.CONNECTING) return;

    if ('WebSocket' in window) {
      this._changeStatus(Status.CONNECTING);

      this._ws = new WebSocket(this._url);

      this._ws.onopen = e => {
        this._onOpen(e);
      };

      this._ws.onclose = e => {
        this._onClose(e);
      };

      this._ws.onerror = e => {
        this._onError(e);
      };

      this._ws.onmessage = e => {
        this._onMessage(e);
      };
    } else {
      console.error('WebSocket not supported by your Browser!');
    }
  }

  disconnect() {
    if (this._status === Status.DISCONNECTED) return;
    if (this._status === Status.DISCONNECTING) return;

    // ref.: http://www.tutorialspoint.com/html5/html5_websocket.htm
    if (this._ws && this._ws.readyState === 1) {
      this._changeStatus(Status.DISCONNECTING);
      this._ws.close();
    }
  }

  isConnected() {
    return this._status === Status.CONNECTED;
  }

  isDisconnected() {
    return this._status === Status.DISCONNECTED;
  }

  send(message) {
    if (this._debug) {
      console.log('WebSocket outbound message', deepCopy(message));
    }
    this._ws.send(JSON.stringify(message));
  }

  avoidReconnect() {
    this._avoidReconnect = true;
  }

  get status() {
    return this._status;
  }

  _onOpen(e) {
    if (this._debug) {
      console.log('WebSocket connected', e);
    }
    this._reconnectAttempt = 0;
    this._changeStatus(Status.CONNECTED);
  }

  _onClose(e) {
    if (this._debug) {
      console.log('WebSocket disconnected', e);
    }

    // eslint-disable-next-line default-case
    switch (this._status) {
      case Status.CONNECTING:
      case Status.CONNECTED:
        // Attempt re-connect upon close
        this._changeStatus(Status.RECONNECTING);
        if (!this._avoidReconnect) {
          setTimeout(() => {
            if (this._debug) {
              console.log('WebSocket reconnecting');
            }
            this.connect();
          }, Math.min(this._reconnectAttempt++ * 1000, 10000));
        }
        // Prolong wait by a second on each reconnect attempt, but not more than 10 seconds
        break;
      case Status.DISCONNECTING:
        this._changeStatus(Status.DISCONNECTED);
        break;
      case Status.DISCONNECTED:
        break;
    }
  }

  _onError(e) {
    console.error('WebSocket error', e);
  }

  _onMessage(e) {
    try {
      this._message = JSON.parse(e.data);
      if (this._debug) {
        console.log('WebSocket inbound message', deepCopy(this._message));
      }
      this.emit(Event.MESSAGE, this._message);

      if (this._message.disconnect) {
        this.avoidReconnect();
        this.disconnect();
      }
    } catch (error) {
      console.error(error, e);
    }
  }

  _changeStatus(status) {
    if (this._status === status) return;
    this._status = status;
    this.emit(Event.STATUS_CHANGED, this._status);
  }
}

export default Handler;
