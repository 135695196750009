import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3)
  },
  button: {
    padding: theme.spacing(1),
    borderWidth: 2,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1)
    },
    '&:hover': {
      borderWidth: 2
    }
  },
  activeBtn: {
    border: '2px solid transparent'
  }
}));

const BuyerSelectTime = ({ selectedTime, calendarData, setSelectedTime }) => {
  const classes = useStyles();

  const renderTime = () => {
    return calendarData.availableTime.map((name, index) => (
      <Button
        key={index}
        className={clsx(classes.button, {
          [classes.activeBtn]: selectedTime === name
        })}
        variant={selectedTime === name ? 'contained' : 'outlined'}
        color={'primary'}
        onClick={() => setSelectedTime(name)}
      >
        {name}
      </Button>
    ));
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.flex}>
        {renderTime()}
      </Box>
    </Box>
  )
};

BuyerSelectTime.propTypes = {
  selectedTime: PropTypes.string,
  calendarData: PropTypes.shape({
    availableTime: PropTypes.array
  }).isRequired,
  setSelectedTime: PropTypes.func.isRequired
}

export default memo(BuyerSelectTime);
