const areEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !areEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      if (typeof val1 === 'function' && typeof val2 === 'function') {
        return val1.toString() === val2.toString();
      }
      return false;
    }
  }

  return true;
};

const isObject = (object) => {
  return object != null && typeof object === 'object';
};

export default areEqual;
