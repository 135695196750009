import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WatchIcon from '@material-ui/icons/Schedule'
import AlertIcon from '@material-ui/icons/NotificationsOutlined';
import TimerIcon from '@material-ui/icons/Timer';
import EventIcon from '@material-ui/icons/Event'
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import useTranslation from 'hook/useTranslation';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  flex: {
    display: 'flex',
    '& svg': {
      marginRight: theme.spacing(1)
    },
    '& p': {
      color: theme.palette.action.darken
    }
  }
}));

const BuyerCalendarSideDetails = ({ className, date, selectedTime }) => {
  const classes = useStyles();
  const { t } = useTranslation();


  return (
    <Paper className={clsx(className, classes.paper)}>
      <Typography variant="body1">{t('Seller name')}</Typography>
      <Typography variant="h5" gutterBottom>{t('Some event name')}</Typography>
      <Box className={classes.flex}>
        <TimerIcon fontSize="small" color={'action'}/>
        <Typography variant="body2" gutterBottom>{t('Duration: 1h')}</Typography>
      </Box>
      <Box className={classes.flex}>
        <AlertIcon fontSize="small" color={'action'}/>
        <Typography variant="body2" gutterBottom>{t('Reminder: 15 min before event')}</Typography>
      </Box>
      {
        selectedTime && (
          <Box className={classes.flex}>
            <WatchIcon fontSize="small" color={'action'}/>
            <Typography variant="body2" gutterBottom>{t('Time')}: {selectedTime}</Typography>
          </Box>
        )
      }
      {
        date && (
          <Box className={classes.flex}>
            <EventIcon fontSize="small" color={'action'}/>
            <Typography variant="body2" gutterBottom>{moment(date).format('MMMM DD.')}</Typography>
          </Box>
        )
      }
    </Paper>
  )
}

BuyerCalendarSideDetails.propTypes = {
  className: PropTypes.string,
  selectedTime: PropTypes.string,
  date: PropTypes.object,
}

export default memo(BuyerCalendarSideDetails);
