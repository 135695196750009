import React, { memo, useEffect, useRef, useState, } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { isIOS } from 'react-device-detect';
import Slider from 'react-slick';
import Fab from '@material-ui/core/Fab';
import NextIcon from '@material-ui/icons/NavigateNext';
import BeforeIcon from '@material-ui/icons/NavigateBefore';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { flatMap, uniq } from 'lodash';
import videojs from 'video.js'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'video.js/dist/video-js.css';
import config from 'config';
import Handler from 'util/ws/handler';
import Event from 'util/ws/event';
import Status from 'util/ws/status';
import { ERROR_TYPE, INFO_TYPE, setToast, SUCCESS_TYPE } from '../../action/toast';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_MESSAGES, EVENT_TYPE } from '../../../../../common/src/util/demo';
import { setProducts, updateParticipantDialog, } from 'action/demo';
import areEqual from '../../util/areEqual';
import ParticipantDialog from 'component/participantDialog';
import PageRoot from 'component/pageRoot';
import PresentationFooter from '../../component/presentationFooter';
import { updateMuted } from '../../action/settings';
import Typography from '@material-ui/core/Typography';
import ParticipantDisconnectedDialog from '../../component/participantDisconnectedDialog';
import BrowserWarningDialog from '../../component/browserWarningDialog';
import { fetchLanguages } from '../../action/translation';
import useTranslation from 'hook/useTranslation';
import { signIn } from '../../action/user';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { translationLanguageFromDemoLanguage } from '../../../../../common/src/util/translation';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.slick-track': {
      height: 'calc(100vh - 88px)'
    },
    '.vjs-poster': {
      backgroundColor: (props) => {
        if (!props.color) return;
        return `rgb(${props.color.split('.').join(',')})`
      },
    },
    '.vjs-volume-panel': {
      pointerEvents: 'auto'
    },
    '.slick-arrow.slick-disabled button': {
      cursor: 'default',
      opacity: 0.5
    },
    '.slick-next:before, .slick-prev:before': {
      fontSize: 30,
    },
    '.slick-next': {
      zIndex: 5,
      right: 180,
      '@media (max-width: 1279px)': {
        right: 50
      }
    },
    '.slick-prev': {
      zIndex: 5,
      left: 180,
      '@media (max-width: 1279px)': {
        left: 50
      },
    },
    '.slick-arrow.slick-prev::before, .slick-arrow.slick-next::before': {
      display: 'none'
    },
    body: {
      backgroundColor: 'black',
      overflow: 'hidden',
    }
  },
  slide: {
    height: 'calc(100vh - 88px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden'
  },
  slideImg: {
    width: '100%',
    height: 'auto !important',
    maxHeight: 'calc(60% + 5.5vh) !important',
    objectFit: 'contain',
    padding: 0,
    backgroundImage: 'url(/presentation/sg_imageview_bg.jpg)',
    backgroundSize: 'cover',
    '@media (max-width: 600px)': {
      height: 'calc(100vh - 45%) !important'
    },
    '@media (max-width: 400px)': {
      height: 'calc(100vh - 60%) !important'
    }
  },
  slideVideo: {
    height: '60% !important',
    flexGrow: 1,
    width: '100% !important',
    backgroundColor: (props) => {
      if (!props.color) return;
      return `rgb(${props.color.split('.').join(',')}) !important`
    },
    '@media (max-width: 600px)': {
      height: 'calc(100vh - 45%) !important'
    },
    '@media (max-width: 400px)': {
      height: 'calc(100vh - 60%) !important'
    }
  },
  slideMediaPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    height: 'calc(60vh + 5.5vh) !important',
    padding: '0 3vh 3vh',
    '@media (max-width: 600px)': {
      height: 'calc(100vh - 45%) !important'
    },
    '@media (max-width: 400px)': {
      height: 'calc(100vh - 60%) !important'
    },
    backgroundImage: 'url(/presentation/sg_imageview_bg.jpg)',
    backgroundSize: 'cover',
  },
  slideTextContent: {
    padding: '0 3vh 3vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: (props) => {
      if (!props.color) return;
      return `rgb(${props.color.split('.').join(',')})`
    },
    width: '100%',
    flexGrow: 1,
    height: '40%',
    color: 'white',
    '& > div': {
      height: '1.3vh',
    },
    '& p': {
      fontSize: '1.55vh',
      lineHeight: 1.2,
      margin: '7px auto'
    },
    '& h2': {
      fontSize: '1.9vh',
      margin: '1.8vh 0 0.5vh'
    }
  },
  wrapper: {
    '& .slick-slide > div': {
      display: 'flex',
      justifyContent: 'center',
      '& > div': {
        width: 'calc(100vh - 21.5%)',
        maxWidth: 618,
        '@media (hover: none) and (pointer: coarse) and (orientation: portrait)': {
          width: '100%',
          maxWidth: '100%',
        },
        '@media (max-width: 550px)': {
          width: '100%',
          maxWidth: '100%'
        },
        '@media (max-height: 650px)': {
          width: 'calc(100vh - 15%)',
        },
        '@media (max-height: 450px)': {
          width: 'calc(100vh - 12%)',
        },
        '@media (max-height: 350px)': {
          width: 'calc(100vh - 10%)',
        },
      }
    }
  },
  footerImage: {
    width: '100%',
    marginTop: 'auto'
  },
  slideIndex: {
    position: 'absolute',
    color: 'white',
    padding: '1vh',
    fontSize: '1.65vh',
    alignSelf: 'flex-end',
    zIndex: 1,
    backgroundColor: (props) => {
      if (!props.color) return;
      return `rgb(${props.color.split('.').join(',')})`
    }
  },
  disableControls: {
    pointerEvents: 'none'
  },
  mainContainer: {
    '@media (max-width: 550px)': {
      width: '100%',
      maxWidth: '100%',
      padding: 0
    }
  },
  demoMessage: {
    width: '100%',
    color: 'rgba(255,255,255,0.9)',
    position: 'absolute',
    top: '45%',
    textAlign: 'center',
    left: 0
  }
}));

/*
const ReadyState = Object.freeze({
  HAVE_NOTHING: 0,
  HAVE_METADATA: 1,
  HAVE_CURRENT_DATA: 2,
  HAVE_FUTURE_DATA: 3,
  HAVE_ENOUGH_DATA: 4,
});
*/
// assuming always ready
const playerReady = player => true;
const Video = Object.freeze({
  STATE_UPDATE_INTERVAL: 1000,
  MAX_DELAY: 1000,
});

const VideoPlayer = memo((props) => {
  const { index, playVideo, videoTime, emitPlayOrPauseVideo, poster, color } = props;
  const [player, setPlayer] = useState();
  const videoRef = useRef();
  const classes = useStyles({ color });

  useEffect(() => {
    let stateUpdateInterval, userActiveInterval;

    setPlayer(videojs(videoRef.current, props, function onPlayerReady() {
      this.on('play', () => {
        emitPlayOrPauseVideo(index, true, this.currentTime(), true);
      });
      this.on('pause', () => {
        emitPlayOrPauseVideo(index, false, this.currentTime(), true);
      });
      this.one('touchstart', () => {
        this.on('touchstart', () => {
          if (this.paused())
            this.play();
          else
            this.pause();
        });
      });
      this.on('seeked', () => {
        // if the player is playing, pause and play will be dispatched
        if (!playVideo) {
          emitPlayOrPauseVideo(index, false, this.currentTime(), true);
        }
      });
      stateUpdateInterval = setInterval(() => {
        emitPlayOrPauseVideo(index, !this.paused(), this.currentTime(), true);
      }, Video.STATE_UPDATE_INTERVAL);
      if (isIOS) {
        userActiveInterval = setInterval(() => {
          if (this.muted()) {
            this.userActive(true);
          }
        }, 100);
      }
    }));

    return () => {
      if (player) {
        player.dispose();
      }
      clearInterval(stateUpdateInterval);
      clearInterval(userActiveInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (player) {
      player.src({
        type: 'video/mp4',
        src: props.sources[0].src,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sources]);

  useEffect(() => {
    if (player && playerReady(player)) {
      if (playVideo) {
        player.play();
      } else {
        player.pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (player) {
      if (videoTime !== undefined && Math.abs(videoTime - player.currentTime()) > Video.MAX_DELAY / 1000) {
        player.currentTime(videoTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTime]);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        preload="auto"
        className={clsx('video-js', classes.slideVideo)}
        data-setup={`{"poster":"${poster}"}`}
      />
    </div>
  );
}, areEqual);

const isVideoFileRegex = /\.(MPG|MP2|MPEG|MPE|MPV|OGG|MP4|M4P|M4V|AVI|WMV|MOV|QT|FLV|SWF|AVCHD)$/i;
const isVideoFile = file => file && isVideoFileRegex.test(file);
const isImageFileRegex = /\.(JPG|JPEG|PNG)$/i;
const isImageFile = file => file && isImageFileRegex.test(file);

const SlideTemplate = Object.freeze({
  MEDIA_AND_TEXT: 1,
  TEXT_ONLY: 2
});

const Slide = memo((
  {
    item,
    index,
    isActive,
    lang,
    cachedVideosList,
    presentation,
    playVideo,
    videoTime,
    emitPlayOrPauseVideo
  }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { color } = presentation.product;
  const classes = useStyles({ color });
  const [videoJsOptions, setVideoJsOptions] = useState();
  const isVideo = isVideoFile(item.media);
  const isImage = isImageFile(item.media);
  const hasSetVideoSrc = useRef(false);

  useEffect(() => {
    if (isVideo) {
      const cachedVideoData = cachedVideosList.find(video => video.url === item.media);
      let extension = item.media.split('.');
      extension = extension[extension.length - 1];

      if (cachedVideoData) hasSetVideoSrc.current = true;
      setVideoJsOptions({
        muted: isIOS,
        playsinline: true,
        controls: true,
        preload: 'none',
        poster: `${item.media}.jpg`,
        sources: [{
          type: cachedVideoData ? `video/${cachedVideoData.extension}` : `video/${extension}`,
          src: cachedVideoData ? URL.createObjectURL(cachedVideoData.blob) : item.media
        }]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedVideosList, item]);

  useEffect(() => {
    if (isIOS && isVideo && isActive) {
      dispatch(setToast({
        open: true,
        message: t('On iOS, you need to unmute the video manually.'),
        alertType: INFO_TYPE,
      }));
    }
  }, [index, isVideo, isActive]);

  return (
    <div className={classes.slide}>
      <div className={classes.slideIndex}>{index + 1}</div>
      {isVideo && isActive && videoJsOptions?.sources[0].src && (
        <VideoPlayer
          {...videoJsOptions}
          index={index}
          playVideo={playVideo}
          videoTime={videoTime}
          emitPlayOrPauseVideo={emitPlayOrPauseVideo}
          color={color}
        />
      )}
      {isImage && (<img alt={'Slide'} className={classes.slideImg} src={item.media}/>)}
      {(!isImage && !isVideo && item.template === SlideTemplate.MEDIA_AND_TEXT) && (
        <div className={classes.slideMediaPlaceholder}/>
      )}
      <div className={classes.slideTextContent}>
        {(item.texts[lang]?.title) && <h2>{item.texts[lang]?.title}</h2>}
        {(item.texts[lang]?.text) && <div dangerouslySetInnerHTML={{ __html: item.texts[lang]?.text }}></div>}
        <img alt={'Veidec logo'} className={classes.footerImage} src={'/presentation/VEIDEC_logo_white.png'}/>
      </div>
    </div>
  );
}, areEqual);

const NextArrow = (props) => {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <span className={props.className}>
      <Fab
        size="small"
        className={classes.arrow}
        onClick={onClick}
      >
        <NextIcon/>
      </Fab>
    </span>
  );
};

const updateWindowTitle = (productShortName, activeSlideIndex) => {
  if (!productShortName || activeSlideIndex === -1)
    return;
  const currentTitle = document.title;
  const titleSeparator = ' | ';
  const separatorIndex = currentTitle.indexOf(titleSeparator);
  const updatedTitle = (separatorIndex === -1 ? currentTitle : currentTitle.substring(0, separatorIndex)) +
    `${titleSeparator}${productShortName}${titleSeparator}${activeSlideIndex + 1}`;
  if (currentTitle !== updatedTitle)
    document.title = updatedTitle;
};

const BeforeArrow = (props) => {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <span className={props.className}>
      <Fab
        size="small"
        className={classes.arrow}
        onClick={onClick}
      >
        <BeforeIcon/>
      </Fab>
    </span>
  )
};

const isTouchDevice = () => {
  return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
};

const PresentationPage = () => {
  const { demoId, participantId } = useParams();

  const [status, setStatus] = useState(Status.DISCONNECTED);
  const [languageCode, setLanguageCode] = useState('SE');
  const [countryCode, setCountryCode] = useState('SE'); // eslint-disable-line no-unused-vars
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [nextPresentationOpen, setNextPresentationOpen] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [videoTime, setVideoTime] = useState(undefined);
  const [ws, setWs] = useState(undefined);
  const [demo, setDemo] = useState(undefined);
  const [participant, setParticipant] = useState(undefined); // participant entry from database of current user
  const [presentation, setPresentation] = useState(undefined);
  const [productShortName, setProductShortName] = useState(undefined);
  const [cachedVideosList, setCachedVideosList] = useState([]);
  const [participantsState, setParticipantsState] = useState(undefined);
  const [hasAudio, setHasAudio] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showParticipantDisconnected, setShowParticipantDisconnected] = useState(false);
  const { openParticipantDialog, products } = useSelector(state => state.demo);
  const userLanguage = useSelector(state => state.user.user?.language);
  const { languages } = useSelector(state => state.translation);

  const classes = useStyles();
  const dispatch = useDispatch();
  const sliderRef = useRef();
  const { t, setLanguage } = useTranslation();

  const isSeller = participant?.isSeller;
  const languagesCodes = uniq(flatMap(presentation?.product.presentation.slides, ({ texts }) => Object.keys(texts)));

  const slideFilter = item => item.texts[languageCode];

  const SLIDER_SETTINGS = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isSeller && !isTouchDevice(),
    nextArrow: <NextArrow/>,
    prevArrow: <BeforeArrow/>,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: false
        }
      },
    ]
  };

  const emitChangeLanguageCode = (_languageCode, broadcast = false) => {
    setLanguageCode(_languageCode);
    if (ws?.isConnected() && isSeller && languageCode !== _languageCode && broadcast) {
      ws.send({
        type: EVENT_TYPE.SET_DEMO_STATE,
        demoState: {
          activeSlideIndex,
          productShortName,
          languageCode: _languageCode,
          countryCode,
          audio: hasAudio
        }
      });
    }
  };

  const emitChangeActiveSlideIndex = (_activeSlideIndex, broadcast = false) => {
    if (_activeSlideIndex === -1)
      return;
    if (window.XtremeViewer) {
      window.XtremeViewer.setActiveSlide(_activeSlideIndex + 1);
    }
    setActiveSlideIndex(_activeSlideIndex);
    sliderRef.current.slickGoTo(_activeSlideIndex);
    if (isSeller && demo && presentation) {
      const currentPresentationIndex = demo.productsShortNames.indexOf(productShortName);
      setNextPresentationOpen(
        _activeSlideIndex === presentation.product.presentation.slides.filter(slideFilter).length - 1 &&
        currentPresentationIndex !== -1 && currentPresentationIndex !== demo.productsShortNames.length - 1
      );
    }
    if (ws?.isConnected() && isSeller && activeSlideIndex !== _activeSlideIndex && broadcast) {
      ws.send({
        type: EVENT_TYPE.SET_DEMO_STATE,
        demoState: {
          activeSlideIndex: _activeSlideIndex,
          productShortName,
          languageCode,
          countryCode,
          audio: hasAudio
        }
      });
    }
  };

  const emitPlayOrPauseVideo = (_activeSlideIndex, playVideo, videoTime, broadcast = false) => {
    if (ws?.isConnected() && isSeller && broadcast) {
      ws.send({
        type: EVENT_TYPE.SET_VIDEO_STATUS,
        activeSlideIndex: _activeSlideIndex,
        playVideo,
        videoTime,
      });
    }
  }

  const emitChangeProductShortName = (_productShortName, broadcast = false) => {
    if (productShortName === _productShortName || !ws?.isConnected())
      return;

    setProductShortName(_productShortName);

    if (window.XtremeViewer) {
      window.XtremeViewer.setProductShortName(_productShortName);
    }

    if (isSeller && broadcast) {
      setActiveSlideIndex(0);
      ws.send({
        type: EVENT_TYPE.SET_DEMO_STATE,
        demoState: {
          activeSlideIndex: 0,
          productShortName: _productShortName,
          languageCode,
          countryCode,
          audio: hasAudio
        }
      });
    }

    ws.send({
      type: EVENT_TYPE.GET_PRESENTATION,
      productShortName: _productShortName,
    });
  };

  const emitSetParticipantIsMuted = (participantId, isMuted, broadcast = false) => {
    if (participant?.id === participantId)
      dispatch(updateMuted(isMuted));
    if (ws?.isConnected() && broadcast) {
      ws.send({
        type: EVENT_TYPE.SET_PARTICIPANT_IS_MUTED,
        participantId,
        isMuted,
      });
    }
  }

  useEffect(() => {
    dispatch(fetchLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!demo || languages.length === 0)
      return;

    if (userLanguage) {
      setLanguage(userLanguage);
      return;
    }

    // Try buyer's browser language first
    const stripCountrySuffix = language => language?.replace(/-.*$/, '');
    const browserLanguage = stripCountrySuffix(
      navigator.languages !== undefined ?
        navigator.languages[0] :
        navigator.language)?.toLowerCase();
    const isSupportedBrowserLanguage = languages.some(({ language }) => language === browserLanguage);
    if (isSupportedBrowserLanguage) {
      setLanguage(browserLanguage);
      return;
    }

    // Try demo languageCode if supported
    const demoLanguage = translationLanguageFromDemoLanguage(demo?.languageCode?.toLowerCase());
    const isSupportedDemoLanguage = languages.some(({ language }) => language === demoLanguage);
    if (isSupportedDemoLanguage) {
      setLanguage(demoLanguage);
      return;
    }

    setLanguage('en');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, isSeller, demo, userLanguage]);

  useEffect(() => {
    if (participant) {
      dispatch(updateParticipantDialog(true));
    }
  }, [participant, dispatch]);

  useEffect(() => {
    dispatch(signIn());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorMessage && ws?.isConnected()) {
      ws.disconnect();
      dispatch(setToast({
        open: true,
        message: t('Disconnected'),
        alertType: ERROR_TYPE,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    if (!demoId || !participantId)
      return;

    const debug = config.environment === 'local';
    const url = `${config.vpdWsUrl}/demo?demoId=${demoId}&participantId=${participantId}`;
    const ws = new Handler(url, debug);

    ws.connect();
    setWs(ws);

    return () => {
      if (ws.isConnected()) {
        ws.avoidReconnect();
        ws.disconnect();
      }
    }
  }, [demoId, participantId]);

  useEffect(() => {
    if (!ws)
      return;
    if (ws?.isDisconnected())
      return;
    dispatch(setToast({
      open: true,
      message: ws?.isConnected() ? t('Connected') : t('Reconnecting...'),
      alertType: ws?.isConnected() ? SUCCESS_TYPE : ERROR_TYPE,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    // Preload video resources in sequence
    (async () => {
      if (presentation) {
        let videoPaths = presentation.product.presentation.slides
          .filter(item => item.media && item.media.endsWith('.mp4'))
          .map(item => ({
            order: item.order,
            url: item.media
          }));

        const splitAt = videoPaths.indexOf(videoPaths.find(item => item.order >= activeSlideIndex));
        videoPaths = [...videoPaths.slice(splitAt), ...videoPaths.slice(0, splitAt)];

        for (const path of videoPaths) {
          const result = await axios.get(path.url, { responseType: 'blob' });
          let extension = path.url.split('.');
          extension = extension[extension.length - 1];
          setCachedVideosList(cachedVideosList => [...cachedVideosList, {
            url: path.url,
            extension,
            blob: result.data,
          }]);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentation])

  useEffect(() => {
    if (!ws)
      return;

    const onStatusChanged = () => {
      if (ws.isConnected()) {
        // if these events are sent too fast, the server will not receive them for some reason
        setTimeout(() => {
          ws.send({ type: EVENT_TYPE.GET_DEMO_STATE });
          ws.send({ type: EVENT_TYPE.GET_DEMO });
          ws.send({ type: EVENT_TYPE.GET_PARTICIPANT });
          ws.send({ type: EVENT_TYPE.GET_ALL_PRODUCTS });
          ws.send({ type: EVENT_TYPE.GET_PARTICIPANTS_STATE });
          ws.send({ type: EVENT_TYPE.PING });
        });
      }
      setStatus(ws.status);
    }

    // To keep the WebSocket active, we play a ping/pong game with the server
    const pingInterval = setInterval(() => {
      if (ws.isConnected())
        ws.send({ type: EVENT_TYPE.PING });
    }, 10000);

    const onMessage = message => {
      const { type } = message;

      switch (type) {

        case EVENT_TYPE.PONG:
          break;

        case EVENT_TYPE.RECEIVED_ERROR: {
          const { disconnect, error } = message;
          if (disconnect) {
            ws.avoidReconnect();
            ws.disconnect();
          }
          dispatch(setToast({
            open: true,
            message: error.replace(/\n/g, '. '),
            alertType: ERROR_TYPE,
            permanent: disconnect,
          }));
          setErrorMessage(error)
          break;
        }

        case EVENT_TYPE.RECEIVED_TEXT_MESSAGE:
          // TODO (chat maybe?)
          break;

        case EVENT_TYPE.RECEIVED_DEMO_STATE: {
          const { demoState } = message;

          setErrorMessage(demoState.endDemo ? ERROR_MESSAGES.DEMO_ENDED : undefined);
          setHasAudio(!!demoState.audio);
          emitChangeActiveSlideIndex(demoState.activeSlideIndex);
          emitChangeLanguageCode(demoState.languageCode);
          emitChangeProductShortName(demoState.productShortName);
          break;
        }

        case EVENT_TYPE.GET_VIDEO_STATUS:
          const { playVideo, videoTime } = message;
          setPlayVideo(playVideo);
          if (message.activeSlideIndex === activeSlideIndex) {
            setVideoTime(videoTime);
          }
          break;

        case EVENT_TYPE.RECEIVED_DEMO:
          setDemo(message.demo);
          break;

        case EVENT_TYPE.GET_END_DEMO:
          if (message.endDemo) {
            setErrorMessage(t('Demo has ended'))
          }
          break;

        case EVENT_TYPE.RECEIVED_PARTICIPANT:
          setParticipant(message.participant);
          break;

        case EVENT_TYPE.RECEIVED_PRESENTATION:
          setPresentation(message.presentation);
          break;

        case EVENT_TYPE.RECEIVED_ALL_PRODUCTS:
          if (message.products) {
            setProducts(message.products);
            dispatch(setProducts(message.products))
          }
          break;

        case EVENT_TYPE.RECEIVED_PARTICIPANTS_STATE:
          const allConnected = message.participantsState.participants
            .every(({ isConnected }) => isConnected);

          // Connection status changed to disconnected for one of the participants.
          const participantDisconnected =
            !!participantsState?.participants
              .filter(({ isConnected }) => isConnected) // previously connected
              .some(({ id: id1 }) =>
                message.participantsState.participants
                  .find(({ id: id2 }) => id1 === id2)
                  ?.isConnected === false // now disconnected
              )

          if (allConnected)
            setShowParticipantDisconnected(false);
          else if (participantDisconnected)
            setShowParticipantDisconnected(true);

          setParticipantsState(message.participantsState);
          for (const { id: participantId, isMuted } of message.participantsState.participants)
            emitSetParticipantIsMuted(participantId, isMuted);
          break;

        case EVENT_TYPE.GET_UPDATE_BUYER:
          if (message.updated) {
            dispatch(updateParticipantDialog(false));
          }
          break;

        case EVENT_TYPE.SET_PARTICIPANT_IS_MUTED: {
          const { participantId, isMuted } = message;
          emitSetParticipantIsMuted(participantId, isMuted);
          break;
        }

        default:
          console.error(`Unknown message type received: ${type}`);
      }
    }

    ws.on(Event.MESSAGE, onMessage);
    ws.on(Event.STATUS_CHANGED, onStatusChanged);

    return () => {
      ws.removeAllListeners(Event.STATUS_CHANGED);
      ws.removeAllListeners(Event.MESSAGE);
      clearInterval(pingInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    emitChangeActiveSlideIndex,
    emitChangeProductShortName,
    emitChangeLanguageCode,
    activeSlideIndex,
    productShortName,
    languageCode,
    participantsState,
    ws,
    ws?._status
  ]);

  const setAudio = (hasAudio) => {
    ws.send({
      type: EVENT_TYPE.SET_DEMO_STATE,
      demoState: {
        activeSlideIndex,
        productShortName,
        languageCode,
        countryCode,
        audio: hasAudio
      }
    });
    dispatch(updateParticipantDialog(false));
    setHasAudio(hasAudio);
  }

  const onUpdateBuyer = ({ id, displayName }) => {
    ws.send({
      type: EVENT_TYPE.UPDATE_BUYER,
      id,
      displayName
    });
  }

  const onEndDemo = () => {
    ws.send({
      type: EVENT_TYPE.END_DEMO,
      demoState: {
        activeSlideIndex,
        productShortName,
        languageCode,
        countryCode,
        audio: hasAudio,
        endDemo: true
      }
    });
  }

  // Sometimes the slider hangs during animation and does not go to the given slide (dirty hack here)
  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef?.current?.innerSlider?.track?.props?.currentSlide !== activeSlideIndex)
        sliderRef?.current?.slickGoTo(activeSlideIndex);
    }, 500);
    return () => clearInterval(interval);
  }, [activeSlideIndex, sliderRef]);

  const renderSlides = () => {
    if (!presentation)
      return null;

    return presentation.product.presentation.slides
      .filter(slideFilter)
      .map((item, index) => (
        <Slide
          key={item.id}
          lang={languageCode}
          isActive={index === activeSlideIndex}
          index={index}
          item={item}
          cachedVideosList={cachedVideosList}
          playVideo={playVideo}
          videoTime={videoTime}
          presentation={presentation}
          emitPlayOrPauseVideo={emitPlayOrPauseVideo}
        />
      ))
  }

  const renderErrorMessageBlock = () => {
    if (errorMessage) {
      return <Typography variant={'h5'} className={classes.demoMessage}>{t(errorMessage)}</Typography>;
    }
  };

  const nextProduct = (demo && demo.productsShortNames.length > 1) ?
    demo.productsShortNames[demo.productsShortNames.indexOf(productShortName) + 1] :
    null;
  const onNextPresentationClicked = () =>
    emitChangeProductShortName(nextProduct, true);
  const onNextPresentationCloseClicked = () => setNextPresentationOpen(false);

  updateWindowTitle(productShortName, activeSlideIndex);

  return (
    <PageRoot className={classes.mainContainer}>
      <div className={classes.wrapper}>
        <BrowserWarningDialog/>
        {renderErrorMessageBlock()}
        {
          errorMessage === undefined && (
            <>
              <div className={clsx({ [classes.disableControls]: !isSeller })}>
                <Slider
                  {...SLIDER_SETTINGS}
                  beforeChange={(oldIndex, newIndex) => emitChangeActiveSlideIndex(newIndex, true)}
                  ref={sliderRef}
                >
                  {renderSlides()}
                </Slider>
              </div>
              <PresentationFooter
                demo={demo}
                hasAudio={hasAudio}
                participant={participant}
                languageCode={languageCode}
                setLanguageCode={lang => emitChangeLanguageCode(lang, true)}
                languagesCodes={languagesCodes}
                productShortName={productShortName}
                setProductShortName={value => emitChangeProductShortName(value, true)}
                products={products}
                participantsState={participantsState}
                setParticipantIsMuted={(participantId, isMuted) => emitSetParticipantIsMuted(participantId, isMuted, true)}
                onEndDemo={onEndDemo}
              />
              {
                participant && (
                  <ParticipantDialog
                    open={openParticipantDialog}
                    participant={participant}
                    isSeller={isSeller}
                    setAudio={setAudio}
                    onUpdateBuyer={onUpdateBuyer}
                  />
                )
              }
              <ParticipantDisconnectedDialog
                open={showParticipantDisconnected}
                setOpen={setShowParticipantDisconnected}/>
            </>
          )
        }
        <Snackbar
          open={nextPresentationOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          message={t('Last slide reached')}
          action={
            <React.Fragment>
              {nextProduct && (
                <Button color="primary" size="small" onClick={onNextPresentationClicked}>
                  {t('Switch to \'%{product}\'', { product: nextProduct })}
                </Button>
              )}
              <IconButton size="small" aria-label="close" color="inherit" onClick={onNextPresentationCloseClicked}>
                <CloseIcon fontSize="small"/>
              </IconButton>
            </React.Fragment>
          }
        >
        </Snackbar>
      </div>
    </PageRoot>
  );
}

export default memo(PresentationPage);
