const translationLanguageFromDemoLanguage = demoLanguageCode => {
  switch (demoLanguageCode.toLowerCase()) {
    case 'sv':
    case 'se':
      return 'sv';
    case 'da':
    case 'dk':
      return 'da';
    case 'be':
    case 'nl':
      return 'nl';
    case 'lu':
    case 'fr':
      return 'fr';
    case 'hu':
      return 'hu';
    case 'no':
      return 'no';
    case 'de':
    case 'at':
      return 'de';
    case 'fi':
    case 'fi2':
      return 'fi';
    default:
      return 'en';
  }
};

// The same for now
const translationLocaleFromDemoLanguage = translationLanguageFromDemoLanguage;

module.exports.translationLanguageFromDemoLanguage = translationLanguageFromDemoLanguage;
module.exports.translationLocaleFromDemoLanguage = translationLocaleFromDemoLanguage;
